import React, { memo, useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import "./style.scss";
import { Container } from "@mui/material";
const PopupUI = () => {
  const [isOpen, setIsOpen] = useState(false);
  const contentStyle = { background: '#000', width: '65%' ,padding:"30px"};
  const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
  const arrowStyle = { color: '#000' }; // style for an svg element
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2', // Màu chính của theme
      },
      secondary: {
        main: '#dc004e', // Màu phụ của theme
      },
    },
  });
  const handleClose = () =>{
    setIsOpen(false);
  }
  const handleClose2h = () =>{
    const nowInMillis = Date.now();
    localStorage.setItem('close-2h', nowInMillis);
    setIsOpen(false);
  }
  useEffect(() => {
    const storedData = localStorage.getItem('close-2h');
    if(storedData){
        const hoursCurrent = Math.floor(Date.now() / 3600000);
        const hoursOld = Math.floor(storedData / 3600000);
        const result = hoursCurrent - hoursOld;
        if(result <=  2){
            setIsOpen(false);
            return
        }
    }
    setIsOpen(true); // Mở popup khi component được tải
  }, []);

  return (
    <Popup  className={"popup"} open={isOpen} onClose={() => setIsOpen(false)} position="right center"  {...{   contentStyle, overlayStyle, arrowStyle }}>
      <div ><span style={{color:"red"}}>Notice :</span><span style={{color:"white"}}>{` We have ensured that all chapters are complete and include all content.\n Due to the removal of the author's introduction chapter, some chapters may not appear in the list.\n Please be assured that there are no missing chapters.`}</span></div>
      <row>
        <Container 
        sx={{
            display: 'flex',
            justifyContent: 'center', // Căn giữa các phần tử theo chiều ngang
            alignItems: 'center', // Căn giữa các phần tử theo chiều dọc (nếu cần)
            flexDirection: 'row', // Đặt hướng của các phần tử con theo hàng (nếu cần)
            // gap: 2, // Khoảng cách giữa các phần tử
            // height: '100vh' // Tùy chọn: Chiều cao của Container nếu muốn căn giữa theo chiều dọc
          }}
        >
        <Button sx={{ marginTop: 2, marginRight:2, minWidth: 100,minHeight: 60, }} variant="contained" color="primary" onClick={handleClose} >
          Close
        </Button>
        <Button  sx={{ marginTop: 2, minWidth: 100,minHeight: 60, }} variant="contained" color="secondary"  onClick={handleClose2h} >
            Close 2 hours
        </Button>
        </Container>
     
      </row>
      
    </Popup>
  );
}

export default memo(PopupUI);
