import React, { useEffect, useRef, useState } from "react";
import { IoSearch, IoHomeOutline } from "react-icons/io5";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { HttpDefault } from "utils/http";
import axios from "axios";
import { data } from "jquery";
export const Search = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(()=>{
    document.querySelector('.input-wapper').value = ''
  },[location])
  useEffect(() => {
    let handler = () => {
      const div = document.getElementById("dropdown-search");

      document.onclick = function (e) {
        // console.log(e.target.id);
        if (e.target.id != "dropdown-search") {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      };
    };
    document.addEventListener("mousedown", handler);
  });

  const [input, setInput] = useState("");
  const [dataSearch, setDataSearch] = useState([]);
  const [imageSearch, setImageSearch] = useState([]);
  // console.log(input);

  const fetchData = async (value) => {
    if (value == "") {
      return setDataSearch([]);
    }
    const result = await axios.get(HttpDefault + "v1/user/search", {
      params: { name: value },
    });
    // console.log(result.data);
    setDataSearch(result.data);
  };

  const timeOut = useRef(null);

  const handleChange = (value) => {
    clearTimeout(timeOut.current);
    
    timeOut.current = setTimeout(() => {
      setInput(value.target.value)
      fetchData(value.target.value);
      setIsVisible(true);
      // console.log(imageSearch);
      // console.log(dataSearch);
    }, 500);
    
  };
  const handleKeyPress = (e)=>{
    if(e.key === "Enter"){
      navigate(`/find-comic?keyword=${e.target.value}`)
    }
    
  };
  return (
    <>
      <div id="row dropdown-search" className="search-border">
        <div className="div__search">
          <input
            className="input-wapper"
            type="text"
            autoComplete="off"
            placeholder="search comic..."
            onKeyPress={handleKeyPress}
            onChange={(e) => handleChange(e)}
          />
          <IoSearch size={20} className="icon-search" />
        </div>
        {/* <ul className="result-search">
        {results.map((value, key) => (
          <li> {value.name} </li>
        ))}
      </ul> */}
        <ul
          id="result-search"
          className={`result-search ${!isVisible ? "inactive" : "active"}`}
          onClick={() => true}
        >
          {(dataSearch || dataSearch.length) ? dataSearch.map((value, key) => (
            <div className="container-result">
              <a className="link-to" href={`/comic-page/${value.slug}`}>
                <div className="container-left">
                  <img
                    className="detail-result-image"
                    src={value.image}
                  />
                </div>
                <div className="container-right">
                  <li className="detail-result-name"> <p>{value.name}</p> </li>
                  <li className="detail-result-author"> 
                  <a href={value.chapter ? `/comic-page/${value.slug}/${value.chapter[0].slug}` : ''}>
  {value.chapter ? value.chapter[0].name : "None"}
</a>
                    {/* {value.type_comic.map((e, index) => {
                    return index === 0 ? ""+ e.name : "," + e.name
                  })
                  } */}
                   </li>
                </div>
              </a>
            </div>
          )) : ""}
        </ul>
      </div>
    </>
  );
};
