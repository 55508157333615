export const ROUTER = {
    USER:{
        HOME:"",
        HOME_PAGE:"/:page",
        HOT:"hot",
        FOLLOW:"profile/follow",
        HISTORY:"history",
        TYPE:"type",
        RANK:"rank",
        FIND_COMIC:"find-comic",
        READ_COMIC:"comic-page/:slugComic/:slugChapter",
        COMIC_PAGE:"comic-page/:slugComic",
        SIGNIN_PAGE:"sign-in",
        SIGNUP_PAGE:"sign-up",
        PROFILE_PAGE:"profile",
        EDIT_PROFILE:"profile/edit",
        FOLLOW_PAGE:"profile/follow",
        COMMENT_PAGE:"profile/comment",
        CHANGE_PASSWORD:"profile/change-password",
    },
    ADMIN:{  
        HOME:"admin/home",
        LOGIN_ADMIN:"admin/login-admin",
        COMIC_MANAGER:"admin/comic-manager",
        TYPE_COMIC:"admin/type-comic",
        ADD_COMIC: "admin/add-comic",
        EDIT_COMIC: "admin/edit-comic",
        ADD_CHAPTER: "admin/add-chapter"
    }
}