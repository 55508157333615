import { memo, useRef, useState, React, useEffect } from "react";
import "../assets/images/logos/favicon.png";
import "../assets/css/styles.min.css";
import { Link } from "react-router-dom";
import "./style.scss";
import LeftMenu from "components/admin/leftMenu";
import { FaPlus } from "react-icons/fa";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { HttpDefault } from "utils/http";
const FormData = require("form-data");
const AddChapter = () => {
  const [open, setOpen] = useState(false);
  const showSidebar = () => {
    setOpen(!open);
  };

  const [files, setFiles] = useState([]);
  const handleImageChange = (event) => {
    const uploadFiles = event.target.files;
    setFiles(uploadFiles);
  };
  const renderFileList = () => {
    return [...files].map((file) => (
      <div className="col-4 img-preview">
        {" "}
        <img src={URL.createObjectURL(file)} />
      </div>
    ));
  };

  const [allComic, setAllComic] = useState([]);
  useEffect(() => {
    const getAllComic = async () => {
      const getAllComic = await axios.get(HttpDefault + "comic/getAllComic");
      setAllComic(getAllComic.data);
    };
    getAllComic();
  }, []);
  const [formData, setFormdata] = useState({
    selected: "",
    chapter: "",
  });

  const handleChange = (e) => {
    setFormdata({ ...formData, [e.target.name]: e.target.value });
  };

  const formAddChapter = new FormData();
  const submitAddComic = async () => {
    if (formData.chapter == "" || formData.chapter < 0) {
      return toast.error("Select chapter");
    }
    if (formData.selected === "") {
      return toast.error("Select comic");
    }
    try {
      Object.keys(files).forEach((key) => {
        formAddChapter.append("photos", files.item(key));
      });
      formAddChapter.append("idComic", formData.selected);
      formAddChapter.append("chapterName", formData.chapter);
      const response = await axios.post(
        HttpDefault + "comic/addChapter",
        formAddChapter
      );

      

      if (response.status === 200) {
        return toast.success("Update success");
      } else {
        return toast.error("update fail");
      }
    } catch (error) {
       
      toast.error("something wrong");
    }
  };
 
  return (
    <>
      <div
          className={`page-wrapper ${open ? "show-sidebar" : ""}`}
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftMenu showSidebar={showSidebar}/>
        <div class="body-wrapper">
          <div class="container-fluid">
            <div class="row">
              <Link
                onClick={submitAddComic}
                className="col-2 btn btn-primary py-8 fs-4 mb-4 rounded-2"
              >
                ADD
                <FaPlus className="icon-plus" />
              </Link>
              <div className="row">
                <label for="exampleInputEmail1" class="form-label">
                  Select Comic
                </label>
                <div className="col-6">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      Comic
                    </InputLabel>
                    <Select
                      name="selected"
                      onChange={handleChange}
                      value={formData.selected}
                      sx={{
                        m: 1,
                        minWidth: "100%",
                        maxHeight: "30px",
                        // "&.MuiPaper-root": {
                        //   top: "500px",

                        // },
                      }}
                    >
                      {allComic.map((value, key) => (
                        <MenuItem key={key} value={value._id}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div class="col-lg-12 d-flex align-items-strech content">
                <div className="col-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Chapter Name
                    </label>
                    <input
                      type="number"
                      name="chapter"
                      onChange={handleChange}
                      class="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <input
                  type="file"
                  accept=".jpg"
                  multiple
                  onChange={handleImageChange}
                />
                <div className="col-12 preview">PREVIEW</div>
              </div>
              <div className="row">{renderFileList()}</div>
            </div>
          </div>
        </div>
      </div>
      <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
      <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
      <script src="../assets/js/sidebarmenu.js"></script>
      <script src="../assets/js/app.min.js"></script>
      <script src="../assets/libs/apexcharts/dist/apexcharts.min.js"></script>
      <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
      <script src="../assets/js/dashboard.js"></script>
    </>
  );
};
export default memo(AddChapter);
