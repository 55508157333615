import { memo, useRef, useState, React, useEffect } from "react"
import '../../homePage/style.scss'
import AdBanner from "utils/ads";
import { FaRegEye } from "react-icons/fa";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Explore from "../../../../utils/explore";
import { hover } from "@testing-library/user-event/dist/hover";
import ReadHistory from "components/user/history";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { HttpDefault } from "utils/http";
import { Link } from "react-router-dom";
const TopRank = () => {
  const [topweek, setTopWeek] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const showTopweek = async () => {
    setLoading(true);
    const result = await axios.get(`${HttpDefault}comic/showtoprank`);
    // console.log(result.data);
    setTopWeek(result.data);
    if(result.data.length > 0 ){
      setLoading(false);
      return
    }
    // console.log(topweek);
    setLoading(true);
  }
  useEffect(() => {
    showTopweek();
  }, []);
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <ReadHistory />
        <TabContext value={value}>
          <Box
            className="container-rank"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              className="tabname"
              onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
            >
              <Tab label="TOP MONTH" value="1" />
              <Tab label="TOP WEEK" value="2" />
              <Tab label="TOP DAY" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {
              loading ? (<div className="grid-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color="warning" />
              </div>) :
                topweek[0].top_month.length > 0 ? topweek[0].top_month.map((e, index) => (
                  <a href={`/comic-page/${e[0].slug}`}>
                    <div key={index} className="container-top">
                    <div className="top-week-index-image">
                      <div className={`color-index `} style={{ height: "25px", width: "25px", background: "black", borderRadius: "15px" }} hover={{ cursor: "pointer", color: "#ffa64d" }}>
                        <p className={`index ${index === 0 ? 'top1' : index === 1 ? 'top2' : index === 2 ? 'top3' : ''}`}>
                          {index + 1 < 10 ? "0" + (index + 1) : index + 1}
                        </p>
                      </div>
                      <img src={e[0].image}></img>
                    </div>

                    <div>
                      <p className="top-week-title">{e[0].name}</p>
                      <div className="chapter-amount-eye">
                        <a href={`/comic-page/${e[0].slug}/${e[0].chaper_number.slug}`}>
                        <p className="left">
                          {" "}
                          {  e[0].chaper_number.name}
                        </p>
                      
                        </a>
                        <p className="right">
                          <FaRegEye className="ic" /> {Explore.roundMath(e[1])}
                        </p>
                      </div>
                    </div>
                  </div>
                  </a>
                  
                )) : (<span></span>)}
          </TabPanel>
          <TabPanel value="2">
            {
              loading ? (<div className="grid-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color="warning" />
              </div>) :
                topweek[0].top_week.length > 0 ? topweek[0].top_week.map((e, index) => (
                  <a href={`/comic-page/${e[0].slug}`}>
                    <div key={index} className="container-top">
                    <div className="top-week-index-image">
                      <div className={`color-index `} style={{ height: "25px", width: "25px", background: "black", borderRadius: "15px" }} hover={{ cursor: "pointer", color: "#ffa64d" }}>
                        <p className={`index ${index === 0 ? 'top1' : index === 1 ? 'top2' : index === 2 ? 'top3' : ''}`}>
                          {index + 1 < 10 ? "0" + (index + 1) : index + 1}
                        </p>
                      </div>
                      <img src={e[0].image}></img>
                    </div>

                    <div>
                      <p className="top-week-title">{e[0].name}</p>
                      <div className="chapter-amount-eye">
                        <a href={`/comic-page/${e[0].slug}/${e[0].chaper_number.slug}`}>
                        <p className="left">
                          {" "}
                          {  e[0].chaper_number.name}
                        </p>
                      
                        </a>
                        <p className="right">
                          <FaRegEye className="ic" /> {Explore.roundMath(e[1])}
                        </p>
                      </div>
                    </div>
                  </div>
                  </a>
                )) : (<span></span>)}
          </TabPanel>
          <TabPanel value="3">
          {
              loading ? (<div className="grid-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color="warning" />
              </div>) :
                topweek[0].top_day.length > 0 ? topweek[0].top_day.map((e, index) => (
                  <a href={`/comic-page/${e[0].slug}`}>
                    <div key={index} className="container-top">
                    <div className="top-week-index-image">
                      <div className={`color-index `} style={{ height: "25px", width: "25px", background: "black", borderRadius: "15px" }} hover={{ cursor: "pointer", color: "#ffa64d" }}>
                        <p className={`index ${index === 0 ? 'top1' : index === 1 ? 'top2' : index === 2 ? 'top3' : ''}`}>
                          {index + 1 < 10 ? "0" + (index + 1) : index + 1}
                        </p>
                      </div>
                      <img src={e[0].image}></img>
                    </div>

                    <div>
                      <p className="top-week-title">{e[0].name}</p>
                      <div className="chapter-amount-eye">
                        <a href={`/comic-page/${e[0].slug}/${e[0].chaper_number.slug}`}>
                        <p className="left">
                          {" "}
                          {  e[0].chaper_number.name}
                        </p>
                      
                        </a>
                        <p className="right">
                          <FaRegEye className="ic" /> {Explore.roundMath(e[1])}
                        </p>
                      </div>
                    </div>
                  </div>
                  </a>
                )) : (<span></span>)}
          </TabPanel>
        </TabContext>
        
        {/* <AdBanner option={`atOptions = {
		'key' : '8d7f38e321ef1c5eff5ca51f523425cf',
		'format' : 'iframe',
		'height' : 600,
		'width' : 160,
		'params' : {}
	};`} url={"//www.topcreativeformat.com/8d7f38e321ef1c5eff5ca51f523425cf/invoke.js"} id={"id-container"}/> */}
       
      
      </Box>
    </>
  )
}

export default TopRank;