import React, { memo, useState } from "react";
import "./style.scss";
import GoToTop from "../theme/btnGoToTop";
import Breadcrumb from "../theme/breadcrumb/breadcrumb";
import { FaUserAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import axios, { AxiosError } from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate   } from 'react-router-dom';
import { HttpDefault } from "utils/http";
const SignUpPage = () => {
  const navigate = useNavigate();
  const [formData, setFormdata]= useState({
    email:'',
    password:'',
    confirmPass:''
  })
  const handleChange=(e)=>{
    setFormdata({...formData, [e.target.name]: e.target.value})
  }

  const handleSubmit=async (e)=>{
   
    if(formData.password !== formData.confirmPass)
    {
      toast.error("Passwords do not match"); return
    }
    if(formData.email.length > 255 || formData.email.length < 6){
      toast.error("Email length should be between 3 and 255 characters");return
    }
    else if(formData.password.length > 255 || formData.password.length < 6){
      toast.error("Password length should be between 6 and 20 characters");return
    }
    try {
      const response = await axios.post(HttpDefault+"v1/user/signup",{email:formData.email, password:formData.password});
      //console.log(response);
      if(response.data.success){
        toast.success("Signup Success");
        navigate("/sign-in")
        return;
      }
      else if(response.data.exists){
        toast.error("Email valid");return
      }
      else if(response.data.error){
        toast.error("Error");return
      }
    } catch (error) {
      //console.log(error)
      toast.error(error.response.statusText);
    }
  }
  return (
    <div className="container page-signup">
    <Breadcrumb></Breadcrumb>
      <div className="row">
        <div className="container w-800">
          <div className="form-signup">
            <form>
              <h3>SIGN UP</h3>
              <lable>Email <sup>*</sup></lable>
              <input name="email" onChange={handleChange} type="email" placeholder="Enter email" required></input>
              <MdOutlineEmail className="icon-user" />
            
              <label>Password <sup>*</sup></label>
              <input name="password" onChange={handleChange} type="password" placeholder="Enter password" required></input>
              <FaLock className="icon-lock" />
              <label>Confirm Password <sup>*</sup></label>
              <input name="confirmPass" onChange={handleChange} type="password" placeholder="Confirm Password" required></input>
              <FaLock className="icon-lock-confirm" />
            
              <button type="button" onClick={handleSubmit}>SIGIN UP</button>
              
            </form>
          </div>
        </div>
      </div>
      <GoToTop></GoToTop>
    </div>
  );
};

export default memo(SignUpPage);
