import { memo, useRef, useState, React, useEffect } from "react";
import "../assets/images/logos/favicon.png";
import "../assets/css/styles.min.css";
import { Link } from "react-router-dom";
import "./style.scss";
import LeftMenu from "components/admin/leftMenu";
import { ToastContainer, toast } from 'react-toastify';
import { FaPlus } from "react-icons/fa";
import JoditEditor from "jodit-react";
import { HttpDefault } from "utils/http";
import axios from "axios";
const AddComicPage = () => {
  const [open, setOpen] = useState(false);
  const showSidebar = () => {
    setOpen(!open);
  };


  const editor = useRef(null);
  const [content, setContent] = useState("");

  const [files, setFiles] = useState([]);

  const [types, setTypes] = useState([]);
  const addComic = async () => {
    var name = document.getElementById("input__name").value;
    var author = document.getElementById("input__author").value;
    var content = editor.current.value;
    var endComic = document.getElementById("input__endcomic");
    var checkbox_type = document.getElementsByClassName("checkbox-type");
    var file_image = document.getElementById("input_file").files[0]
   
    var arrTypeChoose = []
    for (var i = 0; i < checkbox_type.length; i++) {
      if (checkbox_type[i].checked) arrTypeChoose.push(types[i])
    }
    const formData = new FormData();
    formData.append('name', name);
    formData.append('author', author);
    formData.append('content', content);
    formData.append('type', JSON.stringify(arrTypeChoose));
    formData.append('process', endComic.checked);
    formData.append('avatar', file_image);
    try {
      // Make a POST request using Axios
      const response = await axios.post(HttpDefault + "comic/add", formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Set the content type to `multipart/form-data`
        }
      });
      // Handle the response
     
 
      if (Object.keys(response.data).includes("success")) {
        document.getElementById("input__name").value = "";
        document.getElementById("input__author").value = "";
        document.getElementById("input_file").value = null;
        setContent('');
        toast.success("đã thêm thành công truyện!!");
      }else if (Object.keys(response.data).includes("fail")){
        toast.error(response.data.fail);
      } 
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  }
  const handleImageChange = (event) => {
    const uploadFiles = event.target.files;
    setFiles(uploadFiles);
  };
  const renderFileList = () => {
    return [...files].map(file => (
      <div className="col-4 img-preview"> <img className="img__avatar" src={URL.createObjectURL(file)} /></div>
    ))
  };
  const fetchApiType = async () => {
    try {
      const response = await axios.get(HttpDefault + "comic/type/showall")
      setTypes(response.data)
    } catch (ex) {
      return
    }


  }
  useEffect(() => {
    fetchApiType();
  }, [])
  return (
    <>
      <div
         className={`page-wrapper ${open ? "show-sidebar" : ""}`}
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
         <LeftMenu showSidebar={showSidebar} />
        <div class="body-wrapper">
          <header class="app-header">
            <nav class="navbar navbar-expand-lg navbar-light">
              <ul class="navbar-nav">
                <li class="nav-item d-block d-xl-none">
                  <a
                    class="nav-link sidebartoggler nav-icon-hover"
                    id="headerCollapse"
                    href="javascript:void(0)"
                  >
                    <i class="ti ti-menu-2"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                    <i class="ti ti-bell-ringing"></i>
                    <div class="notification bg-primary rounded-circle"></div>
                  </a>
                </li>
              </ul>
            </nav>
          </header>
          <div class="container-fluid">
            <div class="row">
              <Link
                to={"#"}
                className="col-2 btn btn-primary py-8 fs-4 mb-4 rounded-2"
                onClick={addComic}
              >
                ADD COMIC
                <FaPlus className="icon-plus" />
              </Link>

              <form>
                <div className="row">
                  <div className="col-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Name Comic
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="input__name"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Author
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="input__author"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      Process: <input type="checkbox" id="input__endcomic" /> comic is END ?
                    </label>
                  </div>
                </div>
                <div className="row">
                  {types.map((value, key) => (
                    <label className="col-2">
                      <input type="checkbox" className="checkbox-type" />
                      {value.name}
                    </label>
                  ))}
                </div>
                <div class="mt-3 mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Content
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => { }}
                  />
                </div>
                <div className="row">
                  <input
                    type="file"
                    id="input_file"
                    // multiple
                    onChange={handleImageChange}
                  />
                  <div className="col-12 preview">PREVIEW</div>
                </div>
                <div className="row">
                  {renderFileList()}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
      <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
      <script src="../assets/js/sidebarmenu.js"></script>
      <script src="../assets/js/app.min.js"></script>
      <script src="../assets/libs/apexcharts/dist/apexcharts.min.js"></script>
      <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
      <script src="../assets/js/dashboard.js"></script>
    </>
  );
};
export default memo(AddComicPage);
