import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";

export default function Breadcrumb() {
  const color_text = {
    color:"gray",
    fontWeight: "100"
  };
  const location = useLocation(); // Sử dụng hook useLocation từ react-router-dom để lấy thông tin về vị trí hiện tại
  //console.log(location);

  let currentLink = ''; // Biến để theo dõi đường dẫn hiện tại
 
  const crumbs = location.pathname
    .split('/')
    .filter(crumb => crumb !== '') // Loại bỏ các phần tử rỗng từ mảng
    .map(crumb => {
      currentLink += `/${crumb}`; // Cập nhật đường dẫn hiện tại

      return (
        <div className="crumb" key={crumb}>
          <a className="href-link"   href={currentLink}>{crumb.split("-").join(" ")}</a> {/* Tạo liên kết cho từng mảnh breadcrumb */}
        </div>
      );
    });
  // Thêm mảnh breadcrumb "Home" nếu không ở trang chủ
  if (location.pathname !== "/") {
    crumbs.unshift(
      <div className="crumb" key="home">
        <a href="/" className="href-link">Home</a>
      </div>
    );
  }
  return (
    <div className="breadcrumbs">
      {crumbs} {/* Hiển thị tất cả các mảnh breadcrumb */}
    </div>
  );
}
