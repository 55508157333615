import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './style.scss';
import { event } from 'jquery';
import { useLocation, useNavigate   } from 'react-router-dom';

export default function ComboBoxChapter({ listChapter ,slugComic, openLink}) {
  const navigate = useNavigate();
  const location = useLocation();
  const  pathnameArray  = location.pathname.split('/');
  const slugChapter = pathnameArray[pathnameArray.length - 1];
  const [currentChapter,setCurrentChapter]  = React.useState('Chapter');
  const changeChapter = (event,value) => {
    // console.log(value);
    if(openLink){
      window.open("https://www.highratecpm.com/ax94etv3?key=8a80502e13b03435e2a8d6e3fb86b1c5");
    }
    if (value){
      window.location.href = `/comic-page/${slugComic}/${value.slug}`;
      // navigate(`/comic-page/${slugComic}/${value.slug}`);
    }else{
      value = listChapter[0]
    }
    
  };
  React.useEffect(()=>{
    const update = () =>{
      listChapter.map(e=>
        {
          if(e.slug === slugChapter){
            setCurrentChapter(e.name);
          }
        })
      
    }
    update()
  },[slugChapter])
  return (
    <>
      <Autocomplete
        onChange={changeChapter}
        disablePortal
        clearIcon={false}
          
        id="combo-box-demo"
        options={listChapter}
        getOptionLabel={(option) => String(option.name)}
        getOptionValue={(option) => option._id}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={currentChapter} />}
      />
    </>
  );
}
