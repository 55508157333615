import { memo, useRef, useState, React, useEffect } from "react";
import "../assets/images/logos/favicon.png";
import "../assets/css/styles.min.css";
import { Link } from "react-router-dom";
import "./style.scss";
import LeftMenu from "components/admin/leftMenu";
import { FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { FaRegTrashAlt } from "react-icons/fa";
import { MdInfo } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { HttpDefault } from "utils/http";
import axios from "axios";
const ComicManager = () => {

  const [open, setOpen] = useState(false);
  const showSidebar = () => {
    setOpen(!open);
  };

  const [input, setInput] = useState("");
  const [results, setResults] = useState([]);
  const typingTimeOut = useRef(null);

  const fetchData = (value) => {
    const results = allComic.filter((item) => {
      return (
        value && item && item.name && item.author,
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });
    // console.log(results);
    setResults(results);
  };
  const handleChange = (value) => {
    clearTimeout(typingTimeOut.current);
    typingTimeOut.current = setTimeout(() => {
      setInput(value);
      fetchData(value);
    }, 500);
  };
  const handleDelete = async (id) => {
    try {
      // console.log(id);
      const response = await axios.post(`${HttpDefault}comic/delete`, { id:id });

      if (Object.keys(response.data) == "success") {
        toast.success("xóa thành công!")
      }
      const data = [...results].filter(e=>e._id !=id)
      setResults(data);
    } catch (ex) {
      toast.error(ex)
    }

  }
  const [allComic, setAllComic] = useState([]);

  const fetchDataComic = async () => {
    const response = await axios.get(`${HttpDefault}comic/showall`);
    const arrData = response.data;


    setResults(arrData);

  }
  useEffect(() => {
    fetchDataComic();
  }, [])
  return (
    <>
      <div
          className={`page-wrapper ${open ? "show-sidebar" : ""}`}
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftMenu showSidebar={showSidebar} />
        <div class="body-wrapper">
          <div class="container-fluid">
            <div class="row">
              <Link
                to={"/admin/add-comic"}
                className="col-2 btn btn-primary py-8 fs-4 mb-4 rounded-2"
              >
                ADD COMIC
                <FaPlus className="icon-plus" />
              </Link>
              <div class="input-group col-3">
                <div class="form-outline" data-mdb-input-init>
                  <input
                    type="search"
                    class="form-control input-search"
                    onChange={(e) => handleChange(e.target.value)}
                  />
                </div>
                <button type="button" class="btn btn-primary btn-search">
                  <i class="fas fa-search"></i>
                </button>
              </div>

              <div class="col-lg-12 d-flex align-items-strech title">
                <div className="col-3">Name comic</div>
                <div className="col-2 ">Image</div>
                <div className="col-2">Author</div>
                <div className="col-2">Type</div>
              </div>
              {(!results || results.length === 0)
                ? allComic.map((value, key) => (
                  <div className="row comic-content">
                    <div className="col-3">{value.name}</div>
                    <div className="col-2">
                      <img className="img-comic" src={results.find(item => item.includes(value._id))} />
                    </div>
                    <div className="col-2">{value.author}</div>
                    <div className="col-2">{value.type_comic}</div>
                    <div className="col-2">
                      <div className="row">
                        <div className="col-4">
                          <MdInfo className="icon-comic-manager icon-info" />
                        </div>
                        <div className="col-4">
                          <Link to={`/admin/edit-comic/${value._id}`}>
                            <FaEdit className="icon-comic-manager icon-edit" />{" "}
                          </Link>
                        </div>
                        <div className="col-4" >
                          {" "}
                          <FaRegTrashAlt className="icon-comic-manager icon-delete"  onClick={()=>handleDelete(value._id) }/>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : results.map((value, key) => (
                  <div className="row comic-content">
                    <div className="col-3">{value.name}</div>
                    <div className="col-2">
                      <img className="img-comic" src={value.image} />
                    </div>
                    <div className="col-2">{value.author}</div>
                    <div className="col-2">{value.type_comic.map(e => (<p>{e.name}</p>))}</div>
                    <div className="col-2">
                      <div className="row">
                        <div className="col-4">
                          <MdInfo className="icon-comic-manager icon-info" />
                        </div>
                        <div className="col-4">
                          <Link to={`/admin/edit-comic?id=${value._id}`}>
                            {" "}
                            <FaEdit className="icon-comic-manager icon-edit" />{" "}
                          </Link>
                        </div>
                        <div className="col-4"  >
                          {" "}
                          <FaRegTrashAlt className="icon-comic-manager icon-delete"  onClick={()=>handleDelete(value._id) }/>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
      <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
      <script src="../assets/js/sidebarmenu.js"></script>
      <script src="../assets/js/app.min.js"></script>
      <script src="../assets/libs/apexcharts/dist/apexcharts.min.js"></script>
      <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
      <script src="../assets/js/dashboard.js"></script>
    </>
  );
};
export default memo(ComicManager);
