import React, { memo, useState, useEffect } from "react";
import "./style.scss";
import Breadcrumb from "../../theme/breadcrumb/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { CiCircleMinus } from "react-icons/ci";
import { Link } from "react-router-dom";
import LeftMenuAccount from "components/user/LeftMenuAccount";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { HttpDefault } from "utils/http";
import Explore from "utils/explore";
const Follow = () => {

  const navigate = useNavigate();
  const [follow, setFollow] = useState();
  const [loadingFollow, setLoadingFollow] = useState(true);
  const fetchDataGetFollow = async () => {
    try {
      setLoadingFollow(true);
      const response = await axios.post(`${HttpDefault}v1/user/getfollow`, {
        idUser: sessionStorage.getItem("id")
      });

      if (Object.keys(response.data).includes("success")) {
        setFollow(response.data.success);
      }
    } catch (ex) {
      alert("đã có lỗi xảy ra");
      setLoadingFollow(false);
    }
    setLoadingFollow(false);
  }
  useEffect(() => {
    const isLogin = sessionStorage.getItem("isLogin");
    if (!isLogin) {
      navigate("/sign-in")
    }
    fetchDataGetFollow();
  }, [])
  return (

    <div className="container">
      <Breadcrumb></Breadcrumb>
      <div className="row">
        <LeftMenuAccount />
        <div id="follow" className="col-9 col-md-11">
          <div className="row right-information">
            <h3>follow</h3>
            <div className="col-12 follow-detail">
              <div className="row title-follow">
                <div className="col-2"></div>
                <div className="col-6"  >comic name</div>

                <div className="col-4"  >new chap</div>
              </div>
              {
                loadingFollow ? (
                  <div className="grid-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress color="warning" />
                  </div>
                ) :
                  follow.map((e, key) => (
                    <div key={key} className="row content-folow">
                      <div className="col-2 div__image">
                        <img
                          className="img-comic-follow"
                          src={e.image}
                        />
                      </div>
                      <div className="col-6">
                        <div className="row comic-name">
                          <Link to={"#"}>
                            {e.name}
                          </Link>
                        </div>
                         
                          <div className="button__unfollow">
                            <span ><CiCircleMinus className="icon-unfollow" /> Unfollow </span>
                          </div>
                       
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <Link to={`/comic-page/${e.slug}/${e.chapter.slug}`}>{e.chapter.name}</Link>
                        </div>
                        <div className="row">
                          {Explore.UtcToVn(e.chapter.time_upload)}
                        </div>
                      </div>
                    </div>
                  ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Follow);
