import React, { memo, useEffect, useState } from "react";
import "./style.scss";
import GoToTop from "../theme/btnGoToTop";
import Breadcrumb from "../theme/breadcrumb/breadcrumb";
import { FaUserAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import Cookies from 'js-cookie';
import axios, { AxiosError } from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate   } from 'react-router-dom';
import { HttpDefault } from "utils/http";
const SignInPage = () => {
  const navigate = useNavigate();
  const [formData, setFormdata] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setFormdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    if (sessionStorage.getItem("inProcess") ==true) {
      // Ngăn chặn nhiều lần gửi khi đang xử lý
      return;
    }
    sessionStorage.setItem("inProcess", true);
    if(formData.email.length > 255 || formData.email.length < 6){
      toast.error("Email length should be between 6 and 255 characters");return
    }
    else if(formData.password.length > 255 || formData.password.length < 6){
      toast.error("Password length should be between 6 and 20 characters");return
    }
    try {
       const response = await axios.post(`${HttpDefault}v1/user/signin`, formData);
      
      // console.log(response)
      if(response.data.success){
        //console.log("ok");
        toast.success("Correct");
       
       // localStorage.setItem('token', response.data.accessToken);
        Cookies.set('accessToken',  response.data.accessToken, { expires: 7 }); //  thời gian sống của cookie 
        sessionStorage.setItem("isLogin", "true");
        sessionStorage.setItem("id", response.data.id);
        sessionStorage.setItem("name", response.data.name);
        localStorage.setItem("avatar",response.data.image);
        navigate("/");
        return;
      }
      else if(response.data.error){
        //console.log("sai tk mk");
        toast.error("Email or password incorrect");
        return
      }

    } catch (error) {
      // console.log(error)
      
      toast.error(error.response.statusText);
    }
  };
    useEffect(()=>{
    const isLogin=sessionStorage.getItem("isLogin");
    if(isLogin){
      navigate("/")
    }
  },[])
  return (
   
    <div className="container page-signin">
    
      <Breadcrumb></Breadcrumb>
      <div className="row">
        <div className="container w-800">
          <div className="form-signin">
            <form>
              <h3>SIGN IN</h3>
              <lable>Email</lable>
              <input
                name="email"
                type="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              ></input>
              <FaUserAlt className="icon-user" />
              
              <label>Password</label>
              <input
                name="password"
                type="password"
                placeholder="Enter password"
                onChange={handleChange}
                required
              ></input>
              <FaLock className="icon-lock" />
           
              <button type="button" onClick={handleSubmit}>
              
              LOG IN
              </button>
              <lable>
                <a href="#">Forgot password?</a>
              </lable>
            </form>
          </div>
        </div>
      </div>
      <GoToTop></GoToTop>
    </div>
  );
};

export default memo(SignInPage);
