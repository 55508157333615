// import { memo, useRef, useState, React, useEffect } from "react";
// import "./style.scss";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
// import Explore from "../../../utils/explore";
// import Breadcrumb from "../theme/breadcrumb/breadcrumb";
// import { CiCircleInfo } from "react-icons/ci";
// import ComboBoxChapter from "../theme/component/combobox";
// import GoToTop from "../theme/btnGoToTop";
// import { useLocation } from "react-router-dom";
// import { GoChevronRight, GoChevronLeft } from "react-icons/go";
// import { Button } from "@mui/material";
// import axios from "axios";
// import { HttpDefault } from "utils/http";
// import CommentNav from '../../../components/user/comment'
// import { useNavigate } from 'react-router-dom';
// import CircularProgress from '@mui/material/CircularProgress';
// const ReadPage = () => {
//   // useEffect(() => {
//   //   window.addEventListener("scroll", function () {
//   //     var scrollPosition = this.window.pageXOffset || this.document.documentElement.scrollTop
//   //     if (scrollPosition >= 200){
//   //         const div = this.document.getElementById("nav__controller");
//   //         div.style.position = "fixed";
//   //         div.style.top = "0";
//   //         div.style.justifyContent="center";
//   //         console.log("scroll hon 200");
//   //     }else{
//   //         const div = this.document.getElementById("nav__controller");
//   //         div.style.position = "none";
//   //         console.log("scroll be 200");
//   //     }
//   //   });
//   // }, []);
//   /// lưu lịch sử đọc vào localstore
//   const navigate = useNavigate();
//   const location = useLocation();
//   const pathnameArray = location.pathname.split("/");
//   const slugComic = pathnameArray[pathnameArray.length - 2];
//   const slugChapter = pathnameArray[pathnameArray.length - 1];
//   const [valueComic, setValueComic] = useState();
//   const [images, setImages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     const getImgChapter = async () => {
//       try {
//         //set path url for cb

//         setLoading(true);
//         const result = await axios.get(HttpDefault + "comic/getImageComic", {
//           params: { idComic: slugComic, idChapter: slugChapter },
//         });
//         console.log(result);
//         setValueComic(result.data);
//         load_image();
//         // lưu lịch sử đọc
//         saveReadingHistory({
//           comicName: result.data.name,
//           chapterName: result.data.list_chapter.find(chapter => chapter.slug === slugChapter).name,
//           chapterLink: location.pathname,
//           chapterImage: result.data.urlAvatarComic,
//         });
//         //set view chapter 
//         const re = await axios.post(`${HttpDefault}view/update`, {
//             id_chapter: result.data.idChapterCurrent  
//         });
//         console.log(re.data)
//         setLoading(false);
//       } catch (ex) {
//         setLoading(false);
//       }

//     };
//     getImgChapter();
//     // lưu lịch sử đọc
//   }, [slugComic, slugChapter]);

//   const load_image = async ()=>{
//     if(valueComic === undefined){
//       return
//     }
//     const imgs = valueComic.image;

//     for (const img of imgs){
//       const result = await axios.get(HttpDefault + "comic/show-image", {
//         params: { path_image: img},
//       });
//       if(result.status === 200){
//         setImages([...images, result.data.image_decode])
//       }
//     }
//   }
//   const toPrevChap = () => {
//     const currentChap = slugChapter;
//     const indexCurrentChap = valueComic.list_chapter.findIndex(chapter => chapter.slug === currentChap);
//     if (indexCurrentChap === 0) {
//       return;
//     }
//     const prevChapter = valueComic.list_chapter[indexCurrentChap - 1];
//     // console.log(slugComic);
//     navigate(`/comic-page/${slugComic}/${prevChapter.slug}`);
//   };

//   const toNextChap = () => {
//     const currentChap = slugChapter;
//     const indexCurrentChap = valueComic.list_chapter.findIndex(chapter => chapter.slug === currentChap);
//     if (indexCurrentChap === valueComic.list_chapter.length - 1) {
//       return;
//     }
//     const nextChapter = valueComic.list_chapter[indexCurrentChap + 1];
//     navigate(`/comic-page/${slugComic}/${nextChapter.slug}`);
//   }

//   const saveReadingHistory = (historyItem) => {
//     const readingHistory = JSON.parse(localStorage.getItem('visited-comic')) || [];
//     const existingIndex = readingHistory.findIndex(item => item.comicName === historyItem.comicName);
//     console.log(readingHistory[existingIndex]);
//     if (existingIndex !== -1) {
//       const existingItem = readingHistory[existingIndex];
//       existingItem.chapterName = (historyItem.chapterName > readingHistory[existingIndex].chapterName) ? historyItem.chapterName : readingHistory[existingIndex].chapterName;
//       existingItem.chapterLink = historyItem.chapterLink;
//       existingItem.chapterImage = historyItem.chapterImage;
//     } else {
//       readingHistory.unshift(historyItem);
//     }

//     localStorage.setItem('visited-comic', JSON.stringify(readingHistory));
//   };

//   return (
//     <>

//       {valueComic && valueComic.length !== 0 && (
//         <div className="container">
//           <div className="head-read-body">
//             <Breadcrumb></Breadcrumb>
//             <div className="title">
//               <p>{valueComic.name}</p>

//               <p>
//                 {"- Chapter " +
//                   valueComic.list_chapter.find(chapter => chapter.slug == slugChapter).name +
//                   " [ " +
//                   Explore.UtcToVn(valueComic.list_chapter.find(chapter => chapter.slug == slugChapter).time_upload) +
//                   " ] "}
//               </p>
//             </div>
//             <div className="manual">
//               <CiCircleInfo />
//               <em>Use next left (←) or right (→) to next chapter</em>
//             </div>
//             <div id="nav__controller" className="controller-chapter">
//               <div onClick={toPrevChap} className="button-back">
//                 <FaChevronLeft />
//               </div>
//               <ComboBoxChapter listChapter={valueComic.list_chapter} slugComic={slugComic} />
//               <div onClick={toNextChap} className="button-next">
//                 <FaChevronRight />
//               </div>
//             </div>
//           </div>
//           {loading ? (
//             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//               <CircularProgress /> {/* Hiển thị biểu tượng loading tròn */}
//             </div>
//           ) : (
//             <div className="main-read-body">
//               {images === "" ? null:images.map((e, index)=>(
//                 <img key={index} src={`data:image/png;base64,${e}`} alt={`Comic Image ${index}`} />
//               ))}
//             </div>
//           )}

//           <div className="button-bottom">
//             <Button onClick={toPrevChap} className="btn" variant="contained">
//               <GoChevronLeft className="icon" />
//               Prev
//             </Button>
//             <Button onClick={toNextChap} className="btn" variant="contained">
//               Next <GoChevronRight className="icon" />
//             </Button>
//           </div>
//           <Breadcrumb></Breadcrumb>
//           <GoToTop />
//           <div>
//             <CommentNav valueComic={valueComic} idChapter={valueComic.idChapterCurrent}></CommentNav>
//           </div>
//         </div>

//       )}
//     </>
//   );
// };

// export default memo(ReadPage);
import NativeAdBanner from "utils/nativeAds";
import { memo, useRef, useState, useEffect } from "react";
import "./style.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import Explore from "../../../utils/explore";
import Breadcrumb from "../theme/breadcrumb/breadcrumb";
import { CiCircleInfo } from "react-icons/ci";
import ComboBoxChapter from "../theme/component/combobox";
import GoToTop from "../theme/btnGoToTop";
import { useLocation } from "react-router-dom";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { Button } from "@mui/material";
import axios from "axios";
import { HttpDefault } from "utils/http";
import CommentNav from '../../../components/user/comment'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import AdBanner from "utils/ads";
const ReadPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameArray = location.pathname.split("/");
  const slugComic = pathnameArray[pathnameArray.length - 2];
  const slugChapter = pathnameArray[pathnameArray.length - 1];
  const [valueComic, setValueComic] = useState();
  const [images, setImages] = useState([]);
  const [openLink, setLink] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    //load direc link
    const timeDirec = localStorage.getItem('direc-5p');
    if (timeDirec) {
      const hoursCurrent = Math.floor(Date.now() / 60000);
      const hoursOld = Math.floor(timeDirec / 60000);
      const result = hoursCurrent - hoursOld;
      if (result <= 5) {
        setLink(false);
      } else {
        const nowInMillis = Date.now();
        localStorage.setItem('direc-5p', nowInMillis);
        setLink(true);
      }
    } else {
      const nowInMillis = Date.now();
      localStorage.setItem('direc-5p', nowInMillis);
      setLink(true);
    }
    const getImgChapter = async () => {
      try {
        setLoading(true);

        const result = await axios.get(HttpDefault + "comic/getImageComic", {
          params: { idComic: slugComic, idChapter: slugChapter },
        });
        setValueComic(result.data);
        await loadImages(result.data.image);
        saveReadingHistory({
          comicName: result.data.name,
          chapterName: result.data.list_chapter.find(chapter => chapter.slug === slugChapter).name,
          chapterLink: location.pathname,
          chapterImage: result.data.urlAvatarComic,
        });
        const re = await axios.post(`${HttpDefault}view/update`, {
          id_chapter: result.data.idChapterCurrent
        });
        setLoading(false);
      } catch (ex) {
        setLoading(false);
      }
    };

    if (slugComic && slugChapter) {
      getImgChapter();
    }
  }, [slugComic, slugChapter]);

  const loadImages = async (imageArray) => {
    if (!imageArray || imageArray.length === 0) {
      return;
    }
    try {

      const imagePromises = imageArray.map(async (img) => {
        return HttpDefault + "comic/show-image?path_image=" + img;
        // const result = await axios.get(HttpDefault + "comic/show-image", {
        //   params: { path_image: img },
        // });
        // if (result.status === 200) {
        //   return result.data.image_decode;
        // }
        // return null;
      });
      const loadedImages = await Promise.all(imagePromises);

      setImages(loadedImages.filter(img => img !== null));
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  const toPrevChap = () => {

    const currentChap = slugChapter;
    const indexCurrentChap = valueComic.list_chapter.findIndex(chapter => chapter.slug === currentChap);
    if (indexCurrentChap === 0) {
      return;
    }
    const prevChapter = valueComic.list_chapter[indexCurrentChap - 1];
    if (openLink) {
      window.open("https://www.highratecpm.com/ax94etv3?key=8a80502e13b03435e2a8d6e3fb86b1c5");
    }
    window.location.href = `/comic-page/${slugComic}/${prevChapter.slug}`;
    // navigate(`/comic-page/${slugComic}/${prevChapter.slug}`);
  };

  const toNextChap = () => {

    const currentChap = slugChapter;
    const indexCurrentChap = valueComic.list_chapter.findIndex(chapter => chapter.slug === currentChap);
    if (indexCurrentChap === valueComic.list_chapter.length - 1) {
      return;
    }
    if (openLink) {
      window.open("https://www.highratecpm.com/ax94etv3?key=8a80502e13b03435e2a8d6e3fb86b1c5");
    }
    const nextChapter = valueComic.list_chapter[indexCurrentChap + 1];
    window.location.href = `/comic-page/${slugComic}/${nextChapter.slug}`;
    // navigate(`/comic-page/${slugComic}/${nextChapter.slug}`);
  };

  const saveReadingHistory = (historyItem) => {
    const readingHistory = JSON.parse(localStorage.getItem('visited-comic')) || [];
    const existingIndex = readingHistory.findIndex(item => item.comicName === historyItem.comicName);

    if (existingIndex !== -1) {
      const existingItem = readingHistory[existingIndex];
      existingItem.chapterName = (historyItem.chapterName > readingHistory[existingIndex].chapterName) ? historyItem.chapterName : readingHistory[existingIndex].chapterName;
      existingItem.chapterLink = historyItem.chapterLink;
      existingItem.chapterImage = historyItem.chapterImage;
    } else {
      readingHistory.unshift(historyItem);
    }

    localStorage.setItem('visited-comic', JSON.stringify(readingHistory));
  };

  return (
    <>
      {valueComic && valueComic.length !== 0 && (
        <div className="container">
          <div className="head-read-body">
            <Breadcrumb></Breadcrumb>
            <div className="title">
              <p>{valueComic.name}</p>
              <p>
                {"" +
                  valueComic.list_chapter.find(chapter => chapter.slug == slugChapter).name +
                  " [ " +
                  Explore.UtcToVn(valueComic.list_chapter.find(chapter => chapter.slug == slugChapter).time_upload) +
                  " ] "}
              </p>
            </div>

            {/* <AdBanner option={`atOptions = {
		'key' : 'c09dac916066632c3fd0f2983018b5d7',
		'format' : 'iframe',
		'height' : 90,
		'width' : 728,
		'params' : {}
	};`} url={'//www.topcreativeformat.com/c09dac916066632c3fd0f2983018b5d7/invoke.js'} id={''} /> */}
            <div className="manual">
              <CiCircleInfo />
              <em>Use next left (←) or right (→) to navigate chapters</em>
            </div>
            <div className="manual">
              <CiCircleInfo />
              <em style={{ color: 'red' }}>Please wait for the high-quality images to load. If the images do not display, please reload the page. If the problem persists, please report it to us.</em>
            </div>
            <div id="nav__controller" className="controller-chapter">
              <div onClick={toPrevChap} className="button-back">
                <FaChevronLeft />
              </div>
              <ComboBoxChapter listChapter={valueComic.list_chapter} slugComic={slugComic} openLink={openLink} />
              <div onClick={toNextChap} className="button-next">
                <FaChevronRight />
              </div>
            </div>


          </div>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress /> {/* Hiển thị biểu tượng loading tròn */}
            </div>
          ) : (
            <div className="main-read-body">
              {images.length > 0 && images.map((e, index) => (

                <img key={index} src={`${e}`} loading="eager" alt={`Comic Image ${index}`} />
              ))}
            </div>
          )}
          <div className="button-bottom">
            <Button onClick={toPrevChap} className="btn" variant="contained">
              <GoChevronLeft className="icon" />
              Prev
            </Button>
            <Button onClick={toNextChap} className="btn" variant="contained">
              Next <GoChevronRight className="icon" />
            </Button>
          </div>

          <Breadcrumb></Breadcrumb>
          <GoToTop />
          {/* <NativeAdBanner url={"//pl23880431.highrevenuenetwork.com/11079db5c9143ad07e82a17e0217d6ef/invoke.js"} id={"container-11079db5c9143ad07e82a17e0217d6ef"} /> */}

          <div>
            <CommentNav valueComic={valueComic} idChapter={valueComic.idChapterCurrent}></CommentNav>

          </div>
        </div>
      )}
    </>
  );
};

export default memo(ReadPage);
