import React, { useState } from "react";
import { animateScroll } from "react-scroll";
import "./style.scss";
import { HiArrowCircleUp } from "react-icons/hi";

function GoToTop(props) {
 
  const goScrollToTop = () => {
    animateScroll.scrollToTop(); 
  };
  return (
    <div>
      <div className="goTop" onClick={() => goScrollToTop()}>
         <HiArrowCircleUp/>
      </div>
    </div>
  );
}

export default GoToTop;
