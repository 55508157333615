import React, { memo, useRef, useState, useEffect } from "react";
import "./style.scss";
import { Link, Route,useLocation } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoSearch, IoHomeOutline } from "react-icons/io5";
import { ROUTER } from "../../../../utils/router";
import axios, { AxiosError } from "axios";
import { Menu, MenuItem, Paper, Typography } from "@mui/material";
import { CgProfile } from "react-icons/cg";
import { Search } from "../search";
import { FaTimes } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { CookiesProvider, useCookies } from "react-cookie";
import { MdGeneratingTokens } from "react-icons/md";
import { FaUserLarge } from "react-icons/fa6";
import { FaBook } from "react-icons/fa6";
import { FaSignOutAlt } from "react-icons/fa";
import GoToTop from "../btnGoToTop";
import { FaRegUserCircle } from "react-icons/fa";
import imageMain from '../../../../public/logo.png';
import templateUser from '../../../../public/template-user.jpg';
import { HttpDefault } from "utils/http";
import Cookies from 'js-cookie';
const Header = () => {
  const [cookies, setCookie] = useCookies(["accessToken"]);
  const [menus, setMenus] = useState([
    {
      name: "HOT",
      path: ROUTER.USER.HOT,
    },
    {
      name: "FOLLOW",
      path: ROUTER.USER.FOLLOW,
    },
 
    {
      name: "TYPE",
      path: "",
    },
    // {
    //   name: "RANK",
    //   path: ROUTER.USER.RANK,
    // },
    {
      name: "FIND COMIC",
      path: ROUTER.USER.FIND_COMIC,
    },
  ]);
  const location = useLocation();
  const currentUrl = location.pathname;
  sessionStorage.setItem("inProcess", false);
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    if (cookies.accessToken && !sessionStorage.getItem("id") ) {
      sessionStorage.setItem("inProcess", true);
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${HttpDefault}v1/user/checkLogin`,
            {
              headers: {
                accessToken: cookies.accessToken,
              },
            }
          );
          // console.log({msgl:response});
          if (response.data.isLogin) {
            sessionStorage.setItem("isLogin", "true");
            sessionStorage.setItem("id", response.data.token);
            setIsLogin(true);
          }
        } catch (error) {
          console.log(error);
        } finally {
          sessionStorage.setItem("inProcess", false);
        }
      };
      fetchData();
    }
  }, []);
  ///

  // const convertBlobToBase64 = (blob) => {
  //   var reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = function () {
  //     var base64data = reader.result;
  //     console.log(base64data);
  //     return base64data;
  //   };
  // };

  // const getFromCacheOrDownload = async (urlImage) => {
  //   try {
  //     const base64CachedImg = localStorage.getItem(urlImage);

  //     if (base64CachedImg) {
  //       return base64CachedImg;
  //     }

  //     const response = await fetch(urlImage, { mode: "no-cors" });

  //     if (response.status === 429) {
  //       console.log("Too many requests");
  //       return null;
  //     }

  //     const blob = await response.blob();
  //     const imageUrl = URL.createObjectURL(blob);
  //     const base64String = convertBlobToBase64(blob);
  //     console.log(base64String);
  //     return;
  //     localStorage.setItem(urlImage, base64String);

  //     // Trả về đường dẫn URL của ảnh mới tải về
  //     return imageUrl;
  //   } catch (error) {
  //     console.error("Error in getFromCacheOrDownload:", error);
  //     // Xử lý lỗi nếu cần
  //     return null;
  //   }
  // };
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    //check url turn on effect button

    const imgAvatar = localStorage.getItem("avatar");
    const urlParams = new URLSearchParams(imgAvatar);
    const seValue = urlParams.get('se');
    const seTimestamp = new Date(seValue).getTime();
    // console.log(seTimestamp,"///", Date.now());
    const getImageURL = async () => {
      try {
        const response = await axios.post(
          `${HttpDefault}v1/user/getimage`,
          {
            container_name: "user",
            blobName: sessionStorage.getItem("id") + ".jpg",
          }
        );
        // console.log(response);
        const url = response.data.url;
        // await getFromCacheOrDownload(url);
        localStorage.setItem("avatar", url);
        //check 
        const re = await axios.get("https://savemanhua.blob.core.windows.net/user/null.jpg?sv=2023-11-03&st=2023-12-30T13%3A09%3A11Z&se=2023-12-30T13%3A24%3A11Z&sr=b&sp=r&sig=yldZDETEyP0xjzc1KooRMz7h0w4GYIBrKzDO87XAUNg%3D");
        if (re.status === 404) {
          url = ''
        }
        setAvatar(url);
      } catch (error) {
        console.log(error);
      } finally {
        sessionStorage.setItem("inProcess", false);
      }
    };

    if (seTimestamp < Date.now()) {
      // getImageURL();
    } else {
      setAvatar(imgAvatar);
      return;
    }

  }, [localStorage.getItem("avatar")]);


  // DRO PDOW NMENU

  const [types, setTypes] = useState([]);

  const [keyDescribe, setkeyDescribe] = useState(null);

  const showDescribe = (key) => {
    setkeyDescribe(key);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  //////////////////////////////////
  const [anchorEl_profile, setanchorEl_profile] = useState(null);
  const open_profile = Boolean(anchorEl_profile);

  const handleClose_profile = () => {
    setanchorEl_profile(null);
  };
  const handleClick_profile = (event) => {
    setanchorEl_profile(event.currentTarget);
  };
  // END DROP DOWN MENU

  const changeHandleSearch = (q) => {
    const params = {
      page: 1,
      search: q,
    };
  };
  //NAV
  const [stateopen, setStateOpen] = useState(false);

  const navRef = useRef();
  const showNavBar = () => {
    setStateOpen(!stateopen);
    navRef.current.classList.toggle("responsive-active");
  };
  const logOut = () => {
    localStorage.removeItem("avatar");
    sessionStorage.removeItem("isLogin");
    sessionStorage.removeItem("id");
    Cookies.remove("accessToken");
    window.location.href = "/sign-in"
  }
  useEffect(() => {
    //get type
    const getType = async () => {
      const response = await axios.get(`${HttpDefault}comic/type/showall`);
      console.log(response.data);
      if (response.status === 200) {
        setTypes(response.data)
      }
    }
    getType();
  }, [])
  return (
    <>
      <nav ref={navRef}>
        <div className="header__top">
          <div className="container_header__top">
            <div className="row">
              <div className="col-6 header__top_left">
                <div className="div__image-main">
                  <a href="/">
                    <img src={imageMain}></img>
                    
                  </a>
                </div>
              </div>
              <button className="col-6 nav-btn" onClick={showNavBar}>
                <FaBars className="icon-bar" />
              </button>
              <div className="col-6 header__top_right">
                <div className="container_header__top-box_search">
                  <Search />
                </div>
                {(isLogin || sessionStorage.getItem("isLogin")) &&
                  !stateopen ? (
                  <div>
                    <span
                      id="resources-button"
                      onMouseEnter={handleClick_profile}
                      aria-controls={open_profile ? "menu_profile" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open_profile ? "true" : undefined}
                    >
                      <img className="icon-profile" src={avatar === '' ? templateUser : avatar} />

                      <Menu
                        id="menu_profile"
                        anchorEl={anchorEl_profile}
                        open={open_profile}
                        MenuListProps={{
                          "aria-labelledby": "resources-button",
                        }}
                        onClose={handleClose_profile}
                      >
                        <div
                          onMouseLeave={handleClose_profile}
                          className=" "
                        >
                          <MenuItem className="menuItem_profile">
                            {" "}
                            
                            <a href="/profile"><FaUserLarge className="icon-menu-item" />{" "}Profile</a>{" "}
                          </MenuItem>
                          <MenuItem className="menuItem_profile">
                            {" "}
                            
                            <a href="/profile/follow"><FaBook className="icon-menu-item" />{" "}Follow</a>{" "}
                          </MenuItem>
                          <MenuItem onClick={logOut} className="menuItem_profile">
                            <FaSignOutAlt className="icon-menu-item" /> Logout
                          </MenuItem>
                        </div>
                      </Menu>
                    </span>
                  </div>
                ) : (
                  (!isLogin || !sessionStorage.getItem("isLogin")) &&
                  !stateopen && (
                    <div>
                      <a href="/sign-in">
                        <span>Sign In </span>
                      </a>
                      <a href="/sign-up">
                        <span>Sign Up</span>
                      </a>
                    </div>
                  )
                )}
                {/* <Link to={""}>
                  <span>Sign In</span>
                </Link> 
                <Link to={""}>
                  <span>Sign Up</span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        <div className="header__top_bottom">
          <div className="container_header__bottom">
            <ul className="header__top_bottom-list-category">
              <li className="icon-home">
                <a href="/">
                <div>
                  <span>
                    <IoHomeOutline />
                  </span>
                </div>
                </a>
               
              </li>

              {menus?.map((value, key) => (
               
                 <li  key={key}>
                  <div  key={key}  className={`${currentUrl.includes(value.path) && value.name !== "TYPE"?"state-color":""}`}>
                    {value.name === "TYPE" && (
                      <span
                        id="resources-button"
                        onMouseEnter={handleClick}
                        aria-controls={open ? "menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        {value.name}{" "}
                        <IoMdArrowDropdown
                          size={15}
                          className="dropdown_type"
                        />
                        <Menu
                          id="menu"
                          anchorEl={anchorEl}
                          open={open}
                          MenuListProps={{
                            "aria-labelledby": "resources-button",
                          }}
                          onClose={handleClose}
                        >
                          <div className="menuContainer">
                            {types?.map((valueType, keyType) => (
                              <MenuItem
                               key={keyType}
                                
                                onMouseEnter={() => showDescribe(keyType)}
                                className="menuItem"
                              >
                                <a href={`/find-comic?type=${valueType.name}`} style={{padding:'5px',width:"100%",height:"100%",color:"black"}}>
                                {valueType.name}
                                </a>
                                
                              </MenuItem>
                            ))}
                          </div>
                          <div id="describe">
                            {keyDescribe !== null &&
                              types[keyDescribe]?.describe}
                          </div>
                        </Menu>
                      </span>
                    )}
                    {value.name !== "TYPE" && 
                     <a href={`/${value.path}`}><span>{value.name}</span></a> }
                  </div>
                </li>
               
               
              ))}
              <li>
                {stateopen &&
                  (isLogin || sessionStorage.getItem("isLogin")) && (
                    <div>
                      <span
                        id="resources-button"
                        onMouseEnter={handleClick_profile}
                        onMouseLeave={handleClose_profile}
                        aria-controls={
                          open_profile ? "menu_profile" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open_profile ? "true" : undefined}
                      >
                        {avatar ? <img className="icon-profile" src={avatar} /> : <FaRegUserCircle className="icon-profile" />}
                        Profile
                        <IoMdArrowDropdown size={15} />
                        <Menu
                          id="menu_profile"
                          anchorEl={anchorEl_profile}
                          open={open_profile}
                          MenuListProps={{
                            "aria-labelledby": "resources-button",
                          }}
                          onClose={handleClose_profile}
                        >
                          <div className="menuContainer_profile">
                            <MenuItem className="menuItem_profile">
                              {" "}
                              <FaUserLarge className="icon-menu-item" />{" "}
                              <a href={"/profile"}>Profile</a>{" "}
                            </MenuItem>
                            <MenuItem className="menuItem_profile">
                              {" "}
                              <FaBook className="icon-menu-item" /> Follow
                            </MenuItem>
                            <MenuItem className="menuItem_profile">
                              <FaSignOutAlt className="icon-menu-item" /> Logout
                            </MenuItem>
                          </div>
                        </Menu>
                      </span>
                    </div>
                  )}
              </li>
              <li>
                {(!isLogin || !sessionStorage.getItem("isLogin") != "true") &&
                  stateopen && (
                    <div>
                      <a href="/sign-in">
                        <span>Log In </span>
                      </a>
                    </div>
                  )}
              </li>
              <li>
                {(!isLogin || !sessionStorage.getItem("isLogin") != "true") &&
                  stateopen && (
                    <div>
                      <a href="/sign-up">
                        <span>Sign Up</span>
                      </a>
                    </div>
                  )}
              </li>
              {/* <li><div><span>HOT</span></div></li>
                            <li><div><span>FOLLOW</span></div></li>
                            <li><div><span>HISTORY</span></div></li>
                            <li><div>
                                <span>TYPE  </span><IoMdArrowDropdown size={15} className="dropdown_type" />
                               
                            </div>
                             <div className="dropdown-toggle-type">
                                    
                                </div></li>

                            <li><div><span>RANK</span></div></li>
                            <li><div><span>FIND COMIC</span></div></li> */}
            </ul>
          </div>
        </div>
        <GoToTop />
      </nav>
    </>
  );
};

export default memo(Header);
