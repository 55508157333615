import { memo, useRef, useState, React, useEffect } from "react";
import "./style.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Breadcrumb from "../theme/breadcrumb/breadcrumb";
import { Button } from "@mui/material";
import { Link, useParams, useLocation } from "react-router-dom";
import Paginations from "../theme/pagination/index";
// import GoToTop from "../theme/btnGoToTop";
import { FaRegEye, FaComment, FaHeart } from "react-icons/fa";
import Explore from "../../../utils/explore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { event } from "jquery";
import axios from "axios";
import { HttpDefault } from "utils/http";
const FindComicPage = () => {
  const navigate = useNavigate();
  const [types, setTypes] = useState([]);
  const [resultsFind, setResultsFind] = useState([]);
  const [process, setProcess] = useState([
    {
      idProcess: 0,
      name: "All",
    },
    {
      idProcess: 1,
      name: "Complete",
    },
    {
      idProcess: 2,
      name: "Inprocess",
    },
  ]);
  const [sorts, setSorts] = useState([
    {
      idSort: 0,
      name: "Laster",
    },
    {
      idSort: 1,
      name: "New",
    },
    {
      idSort: 2,
      name: "Top all",
    },
    {
      idSort: 3,
      name: "Top month",
    },
    {
      idSort: 4,
      name: "Top week",
    },
    {
      idSort: 5,
      name: "Top day",
    },
    {
      idSort: 6,
      name: "Top follow",
    },
    {
      idSort: 7,
      name: "Top comment",
    },
    {
      idSort: 8,
      name: "Chapter",
    },
  ]);
  const [typeSelect, setTypeSelect] = useState("");
  const [numberPage, setNumberPage] = useState(1);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = !queryParams.get("type") ? "all" : queryParams.get("type");
  const status = !queryParams.get("status") ? 0 : queryParams.get("status");
  const keyword = !queryParams.get("keyword") ? "" : queryParams.get("keyword");
  const sort = !queryParams.get("sort") ? 0 : queryParams.get("sort");
  const page = !queryParams.get("page") ? 0 : queryParams.get("page");
  const handleChange_type = (event) => {
    const selectedType = event.target.value;
    navigate(`/find-comic?type=${selectedType}`);
  };
  const handleChange_sort = (event) => {
    const selectedSort = event.target.value;
    navigate(`/find-comic?type=${type}&status=${status}&sort=${selectedSort}`);
  };
  useEffect((e) => {
    const fetchDataType = async () => {
      const response = await axios.get(`${HttpDefault}comic/type/showall`);
      setTypes(response.data);
    }
    //show comic 
    const fetchShowComic = async () => {
      const response = await axios.get(`${HttpDefault}comic/findcomicpage`, {
        params: {
          type: type,
          status: status,
          sort: sort,
          page: page,
          keyword: decodeURIComponent(keyword)
        }
      })
      setResultsFind(response.data.dataCopy)
      setNumberPage(response.data.avg_page)
      // console.log(response.data.dataCopy)
    }
    fetchDataType();
    fetchShowComic();
  }, [page, type, keyword])
  //console.log(sorts[sort].name);

  return (
    <>
      <div className="container container-find">
        <Breadcrumb />
        <div className="row">
          <div className="col-9 col-md-12 filter">
            <div className="row title-h2">
              <h2>Find comic</h2>
              {/* ///////////////////////////////////////////// */}
              {/* {"FORM SELECT TYPES"} */}
              <div className="col-md-12 select-types">
                <FormControl sx={{ m: 1, minWidth: "90%" }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Types
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={type}
                    onChange={handleChange_type}
                    sx={{
                      m: 1,
                      minWidth: "100%",
                      maxHeight: "30px",
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {types.map((value, key) => (
                      <MenuItem key={key} value={value.name}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* {"/////////////////////////////////////////////"} */}
              <div className="row sort">
                <div className="col-3"></div>
                <div className="col-9 btn-process">

                  {process.map((value, key) => (
                    <Button style={{ padding: "0px" }}
                      className={`col-2 process ${status == key && "active"}`}
                      variant="outlined"
                    >
                      <a
                        href={`/find-comic?type=${type}&status=${value.idProcess}`}
                      >
                        {value.name}
                      </a>
                    </Button>
                  ))}
                </div>
              </div>
            </div>

            <div className="row sort">
              <div className="col-3">Sort by:</div>
              <div className="col-9">
                {sorts.map((value, key) => (
                  <Button
                    style={{ padding: "0px" }}
                    className={`col-4 btn-sort ${sort == key && "active"}`}
                    variant="outlined"
                  >
                    <a
                      href={`/find-comic?type=${type}&status=${status}&sort=${value.idSort}`}
                    >
                      {value.name}
                    </a>
                  </Button>
                ))}
              </div>
            </div>
            {/* {"/////////////////////////////////////////////"}
            {"FORM SELECT SORTS"} */}
            <div className="col-md-12 select-sorts">

              <FormControl sx={{ m: 1, minWidth: "90%" }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  sorts
                </InputLabel>
                <Select
                  value={sort}
                  onChange={handleChange_sort}
                  sx={{
                    m: 1,
                    minWidth: "100%",
                    maxHeight: "30px",
                    // "&.MuiPaper-root": {
                    //   top: "500px",

                    // },
                  }}
                >
                  {sorts.map((value, key) => (
                    <MenuItem key={key} value={value.idSort}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* {"/////////////////////////////////////////////"} */}
            <div className="row content">
              <div className="col-lg-12 col-md-12 col-xl-12 content-main-left">
                <div className="grid-container">
                  {resultsFind.map((item, key) => (
                    <div key={key} className="grid-item">
                      <div className="image">
                        <a key={key} href={`/comic-page/${item.slug}`}>
                          <img src={item.image}></img>
                        </a>
                        {/* <div className="content-image">
                          <FaRegEye className="content-image-icon" />
                          {Explore.roundMath(item.count_seen)}
                          <FaComment className="content-image-icon" />
                          {Explore.roundMath(item.count_comment)}
                          <FaHeart className="content-image-icon" />
                          {Explore.roundMath(item.count_like)}
                        </div> */}
                      </div>
                      <div className="content-detail">
                        <a key={key} href={`/comic-page/${item.slug}`}>
                          <p className="title">{item.name.length > 22 ? item.name.substring(0, 22) + "..." : item.name}</p>  </a>
                        {item.chapter.map((i, key) => (
                          <a key={key} href={`/comic-page/${item.slug}/${i.slug}`}>
                            <div className="chapter">
                              <p className="name-title"> {i.name} </p>
                              <p className="name-time">
                                {" "}
                                {Explore.convertDataToK(i.time_upload)}{" "}
                              </p>
                            </div>
                          </a>

                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 types">
            <h2>Types</h2>
            <ul>
              {types.map((value, key) => (
                <li key={key} className={`col-6`}>
                  <a className={`a__link ${value.name === type ? "a__link-color" : ""} `} href={`/find-comic?type=${value.name}`}>
                    {value.name}
                  </a>{" "}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {Paginations(numberPage)}
      </div>
    </>
  );
};
export default memo(FindComicPage);
