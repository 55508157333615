import { memo, useRef, useState, React ,useEffect} from "react";
import "../assets/images/logos/favicon.png";
import "../assets/css/styles.min.css";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";
import LeftMenu from "components/admin/leftMenu";
import { FaPlus, FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import JoditEditor from "jodit-react";
import { FaRegTrashAlt } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { HttpDefault } from "utils/http";
import queryString from 'query-string';

const AddComicPage = () => {
  const [open, setOpen] = useState(false);
  const showSidebar = () => {
    setOpen(!open);
  };

  const editor = useRef(null);
  const location = useLocation();
  const id_upddate = queryString.parse(location.search).id;  
  
  const [comic, setComic] = useState({});

  const [types, setTypes] = useState([]);

  const [files, setFiles] = useState([]);
  const handleImageChange = (event) => {
    const uploadFiles = event.target.files;
    setFiles(uploadFiles);
  };
 
  const renderFileList = () => {
    // console.log(files);
    return [...files].map((file) => (
      <div className="col-4 img-preview">
        {" "}
        <img src={file} />
      </div>
    ));
  };
  const btnEditComic =async ()=>{
    var name = document.getElementById("input__name").value;
    var author = document.getElementById("input__author").value;
    var content = editor.current.value;
    // var endComic = document.getElementById("input__endcomic");
    var checkbox_type = document.getElementsByClassName("checkbox-type");
    // var file_image = document.getElementById("input_file").files[0]
    // console.log(file_image);
    var arrTypeChoose = []
    for (var i = 0; i < checkbox_type.length; i++) {
      if (checkbox_type[i].checked) arrTypeChoose.push(types[i])
    }
    // console.log(arrTypeChoose);
    const jsonDe = {
      id:id_upddate,
      name:name,
      author:author,
      content:content,
      type : JSON.stringify(arrTypeChoose)
    }
    // const formData = new FormData();
    // formData.append('id', id_upddate);
    // formData.append('name', name);
    // formData.append('author', author);
    // formData.append('content', content);
    // formData.append('type', JSON.stringify(arrTypeChoose));
    // formData.append('process', endComic.checked);
    // formData.append('avatar', file_image);
    try {
      // Make a POST request using Axios
      const response = await axios.post(`${HttpDefault}comic/updatebyid`, jsonDe);
      // Handle the response
      // console.log(response.data);
 
      if (Object.keys(response.data).includes("success")) {
        toast.success("đã chỉnh sửa truyện!!");
      }else if (Object.keys(response.data).includes("fail")){
        toast.error(response.data.fail);
      } 
    } catch (error) {
      // Handle the error
      console.error(error);
    }

  }
  const [content, setContent] = useState("");
  const fetchApiType = async () => {
    try {
      //get type
      const response = await axios.get(HttpDefault + "comic/type/showall")
      setTypes(response.data)

      const response_comic = await axios.get( `${HttpDefault}comic/showbyid?id=${id_upddate}`)
      setComic(response_comic.data)
      setContent(response_comic.data.description)
      setFiles([response_comic.data.image])
      // console.log("render");
    } catch (ex) {
      // console.log(ex);
      return
    }


  }
  useEffect(() => {
    fetchApiType();
  }, [])
  
  return (
    <>
      <div
          className={`page-wrapper ${open ? "show-sidebar" : ""}`}
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >

         <LeftMenu showSidebar={showSidebar} />

        <div class="body-wrapper">
          <header class="app-header">
            <nav class="navbar navbar-expand-lg navbar-light">
              <ul class="navbar-nav">
                <li class="nav-item d-block d-xl-none">
                  <a
                    class="nav-link sidebartoggler nav-icon-hover"
                    id="headerCollapse"
                    href="javascript:void(0)"
                  >
                    <i class="ti ti-menu-2"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                    <i class="ti ti-bell-ringing"></i>
                    <div class="notification bg-primary rounded-circle"></div>
                  </a>
                </li>
              </ul>
            </nav>
          </header>
          <div class="container-fluid">
            <div class="row">
              <Link
                to={"#"}
                className="col-2 btn btn-warning py-8 fs-4 mb-4 rounded-2 btn-edit"
                onClick={btnEditComic}
              >
                EDIT COMIC
                <FaEdit className="icon" />
              </Link>

              <form>
                <div className="row edit-comic">
                  <div className="col-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Name Comic
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="input__name"
                        //onChange={handleInputChange}
                        onChange={e =>{
                          const newName = e.target.value;
                          setComic(prevComic => ({ ...prevComic, name: newName }));
                          
                        } }
                        value={comic.name}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Author
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="input__author"
                        onChange={e =>{
                          const newName = e.target.value;
                          setComic(prevComic => ({ ...prevComic, author: newName }));
                       
                        } }
                        value={comic.author}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <label className="form-label">
                    Process: <input type="checkbox" /> comic is END ?
                  </label>
                </div>
                <div className="row edit-comic">
                  <label className="form-label"> TYPES</label>
                  {types.map((value, key) => (
                    <label key={key} className="col-2">
                      <input
                        type="checkbox"
                        checked={  comic.type_comic === undefined? false:
                        comic.type_comic.map( tp_comic => tp_comic)
                          .includes(value._id)}
                        className="checkbox-type"
                        onChange={e =>{
                          const newName = e.target.checked;
                          // console.log(newName);
                          if (newName) {
                            // console.log(comic);
                            comic.type_comic.push(types[key]._id);
                             
                            setComic(pre =>({...comic,type_comic:comic.type_comic}));
                          } else {
                           
                            setComic(pre =>({...comic,type_comic:comic.type_comic.filter((item) => item !== value._id)}));
                          }
                          // console.log(comic);
                        } }
                      />
                      {value.name}
                    </label>
                  ))}
                </div>
                <div class="mt-3 mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Content
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                  />
                </div>
                {/* <div className="row">
                  <input
                    className="col-6"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <div className="col-12 preview">PREVIEW</div>
                </div> */}
                <div className="row">{renderFileList()}</div>
                {/* <div className="row mt-2">
                  <div className="row mb-2">
                    <Link
                      to={"/admin/add-chapter"}
                      className="col-2 btn btn-primary"
                      data-toggle="modal"
                      data-target="#popup-addchapter"
                    >
                      ADD CHAPTER
                      <FaPlus className="icon" />
                    </Link>
                  </div>

                  {comic.list_chapter.map((list) => (
                    <div className="row list-chap">
                      <div className="col-6">{list.name}</div>
                      <div className="col-5">{list.time}</div>
                      <div className="col-1">
                        <FaEdit className="icon-edit-chap" />{" "}
                        <FaTrash className="icon-delete-chap" />
                      </div>
                    </div>
                  ))}
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
      <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
      <script src="../assets/js/sidebarmenu.js"></script>
      <script src="../assets/js/app.min.js"></script>
      <script src="../assets/libs/apexcharts/dist/apexcharts.min.js"></script>
      <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
      <script src="../assets/js/dashboard.js"></script>
    </>
  );
};
export default memo(AddComicPage);