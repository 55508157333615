import HomePage from "./pages/user/homePage";
import ReadPage from "./pages/user/readPage";
import ComicPage from "pages/user/comicPage";
import MasterLayout from "./pages/user/theme/masterLayout";
import { ROUTER } from "./utils/router";
import { Routes, Route } from "react-router-dom";
import SignInPage from "pages/user/signInPage";
import SignUpPage from "pages/user/signUpPage";
import LoginAdmin from "pages/admin/loginAdmin";
import AdminHome from "pages/admin/homePage";
import ComicManager from "pages/admin/comicManager";
import AddComicPage from "pages/admin/addComicPage";    
import AddChapter from "pages/admin/addChapter";
import EditComicPage from "pages/admin/editComic";
import ProfilePage from "pages/user/profilePage";
import EditProfile from "pages/user/profilePage/editProfile";
import Follow from "pages/user/profilePage/follow";
import CommentPage from "pages/user/profilePage/comment";
import FindComicPage from "pages/user/findComicPage";
import AddType from "pages/admin/addType";
import ChangePassword from "pages/user/profilePage/changePassword";
import ErrorPage from "pages/error"
const renderUserRouter = () => {
  const adminRouter = [
    {
      path: ROUTER.ADMIN.HOME,
      component: <AdminHome />,
    },
    {
      path: ROUTER.ADMIN.LOGIN_ADMIN,
      component: <LoginAdmin />,
    },
    {
      path: ROUTER.ADMIN.COMIC_MANAGER,
      component: <ComicManager />,
    },
    {
      path: ROUTER.ADMIN.ADD_COMIC,
      component: <AddComicPage />,
    }, 
    {
      path: ROUTER.ADMIN.EDIT_COMIC,
      component: <EditComicPage/>
    },
    {
      path: ROUTER.ADMIN.ADD_CHAPTER,
      component: <AddChapter/>
    },
    {
      path: ROUTER.ADMIN.TYPE_COMIC,
      component: <AddType/>
    },
  ];
  const userRouter = [
    {
      path: ROUTER.USER.HOME,
      component: <HomePage />,
    },
    {
      path: ROUTER.USER.HOME_PAGE,
      component: <HomePage />,
    },
    {
      path: ROUTER.USER.READ_COMIC,
      component: <ReadPage />,
    },
    {
      path: ROUTER.USER.COMIC_PAGE,
      component: <ComicPage />,
    },
    {
      path: ROUTER.USER.SIGNIN_PAGE,
      component: <SignInPage />,
    },
    {
      path: ROUTER.USER.SIGNUP_PAGE,
      component: <SignUpPage />,
    },
    {
      path: ROUTER.USER.PROFILE_PAGE,
      component: <ProfilePage />,
    },
    {
      path: ROUTER.USER.EDIT_PROFILE,
      component: <EditProfile />,
    },
    {
      path: ROUTER.USER.FOLLOW_PAGE,
      component: <Follow />,
    },
    {
      path: ROUTER.USER.COMMENT_PAGE,
      component: <CommentPage/>,
    },
    {
      path: ROUTER.USER.FIND_COMIC,
      component: <FindComicPage/>,
    },
    {
      path: ROUTER.USER.CHANGE_PASSWORD,
      component: <ChangePassword/>,
    },
  ];
  return (
    <MasterLayout>
      <Routes>
        {userRouter.map((item, key) => (
          <Route key={key} path={item.path} element={item.component} />
        ))}
        {adminRouter.map((item, key) => (
          <Route key={key} path={item.path} element={item.component} />
        ))}
        
      </Routes>
    </MasterLayout>
  );
};

const RouterCustom = () => {
  return renderUserRouter();
};
export default RouterCustom;
