class Explore {
    static convertDataToK(time) {
        // Chuỗi thời gian UTC
        const utcTimeString = time;

        // Tạo đối tượng Date từ chuỗi thời gian UTC
        const utcTime = new Date(utcTimeString);

        // Lấy thời gian hiện tại của máy chủ (client)
        const currentTime = new Date();

        // Tính toán khoảng cách giữa hai thời điểm
        const timeDifference = currentTime - utcTime;
        // console.log({ utcTime, timeDifference });
        // Chuyển đổi khoảng cách thời gian từ miliseconds sang ngày
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        const hoursDifference = timeDifference / (1000 * 60 * 60 );
        const minusDifference = timeDifference / (1000 * 60  );
        const secondsDifference = timeDifference / (1000   );
        if (secondsDifference < 60){
            return Math.ceil(secondsDifference) + " seconds ago";
        }
        if (minusDifference < 60){
            return Math.ceil(minusDifference) + " minutes ago";
        }else if(hoursDifference < 24){
            return Math.ceil(hoursDifference) + " hours ago";
        }else if (daysDifference > 0) {
            return Math.ceil(daysDifference) + " days ago";
        }  

        // return Math.ceil(daysDifference);
    }

    static roundMath(number) {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + "M";
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + "K";
        }
        return number;
    }
    static UtcToVn(time) {
        var localDate = new Date(time);
        var month = localDate.getMonth() + 1
        var timeShow = "" +this.Zfill(localDate.getDate(),2)  + "/" + this.Zfill(month,2) + "/" + localDate.getFullYear() +"  "+ this.Zfill(localDate.getHours(),2) +":"+this.Zfill(localDate.getMinutes(),2)
        return timeShow
    }
    static Zfill(number, width) {
        let numberStr = number.toString() 
        while(numberStr.length < width){
            numberStr = "0" + numberStr
        }
        return numberStr;
    }
}
export default Explore;