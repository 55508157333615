import React, { memo, useState,useEffect } from "react";
import "./style.scss";
import Breadcrumb from "../../theme/breadcrumb/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { CiCircleMinus } from "react-icons/ci";
import { Link } from "react-router-dom";
import LeftMenuAccount from "components/user/LeftMenuAccount";
import { useNavigate   } from 'react-router-dom';
const CommentPage = () => {

 const navigate = useNavigate();
  useEffect(()=>{
    const isLogin=sessionStorage.getItem("isLogin");
    if(!isLogin){
      navigate("/")
    }
  },[])
  return (
    <div className="container">
      <Breadcrumb></Breadcrumb>
      <div className="row">
        <LeftMenuAccount />

        <div className="col-9">
          <div className="row right-information">
            <h3>comment</h3>
            <div className="col-12 comment-detail">
              <div className="row title-comment">
                <div className="col-1"></div>
                <div className="col-5">comic name</div>
                <div className="col-3">time</div>
                <div className="col-3">comment</div>
              </div>
              <div className="row content-comment">
                <div className="col-1">
                  <img
                    className="img-comic-comment"
                    src="https://www.nettruyenus.net/images/comics/cung-dinh-luu-anh-de-yeu-duong-phat-duong.jpg"
                  />
                </div>
                <div className="col-5">
                  <div className="row comic-name">
                    <Link to={"#"}>
                      Thanh Niên Hạng F Chính Là Anh Trai Của 1 Cô Em Gái Dễ
                      Thương
                    </Link>
                  </div>
                </div>
                <div className="col-3">
                  <div className="row">
                    <Link to={"#"}>Chap 10</Link>
                  </div>
                  <div className="row">
                  12-12-2009
                  </div>
                </div>
                <div  className="col-3 comment">
                  <p>hana yeoq juyq aush poing hungu</p>
                </div>
              </div>
    
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CommentPage);
