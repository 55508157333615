import { memo, useRef, useState, React } from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { TfiAngleDoubleRight } from "react-icons/tfi";
import { Link, Route } from "react-router-dom";
import { TiDeleteOutline } from "react-icons/ti";
const ReadHistory = () => {
  const location = useLocation();
  // const valueHistory = JSON.parse(localStorage.getItem("visited-comic"));
  const [valueHistory, setValueHistory] = useState(
    JSON.parse(localStorage.getItem("visited-comic"))
    )
  
 
  const deleteHistory = (name) => {
    const updatedComics = valueHistory.filter((comic) => comic.comicName !== name);
     
    localStorage.setItem("visited-comic", JSON.stringify(updatedComics));
    setValueHistory(updatedComics)
  };
  return (
    <>
     <h5>HISTORY</h5> 
      { valueHistory && valueHistory.length !== 0 && (
        
        <div className="history">
          
          {valueHistory.map((value, key) => (
            <div className="row history-detail" key={key}>
              <div className="col-3">
                <img src={value.chapterImage}></img>
              </div>
              <div className="col-7">
                <div className="row">
                  <p className="h-name-comic"> {value.comicName} </p>
                </div>
                <div className="row">
                  <p> {value.chapterName} </p>
                </div>
                <div className="row">
                  <p className="p__link--size">
                    {" "}
                    <Link to={value.chapterLink}>
                      Continue <TfiAngleDoubleRight />{" "}
                    </Link>{" "}
                  </p>
                </div>
              </div>
              <div className="col-1">
                <TiDeleteOutline
                // bọc deleteHistory trong hàm mới để lúc render không bị gọi
                  onClick={() => deleteHistory(value.comicName)}
                  className="icon-delete"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ReadHistory;
