import { memo, useRef, useState, React, useEffect } from "react";
import "../assets/images/logos/favicon.png";
import "../assets/css/styles.min.css";
import { Link } from "react-router-dom";
import "./style.scss";
import { FaPlus, FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import LeftMenu from "components/admin/leftMenu";
import { HttpDefault } from "utils/http";
import axios from "axios";
const AddTypePage = () => {

  const [open, setOpen] = useState(false);
  const showSidebar = () => {
    setOpen(!open);
  };
  // const [content, setContent] = useState("");

 
  const [types, setTypes] = useState([]);
  const addTypeComic = async () => {
    var name = document.getElementById("input__nametype").value;
    if(name === "") return;
    //add type
    const response = await axios.get(`${HttpDefault}comic/type/add?name=${name}`)
     
    // setTypes(response.data)
    // const newTypes = [...types, { name: name }];
    // setTypes(newTypes);
    document.getElementById("input__nametype").value = ''
  }
  const deleteTypeComic =async (id) =>{
    
    const response = await axios.get(`${HttpDefault}comic/type/deletebyid?id=${id}`)
     
    // let arrtype = types.filter( (e)=> e.name!=name); 
    // setTypes(arrtype);
  }
  // const handleImageChange = (event) => {
  //   const uploadFiles = event.target.files;
  //   setFiles(uploadFiles);
  // };
  const fetchApiType = async () =>{
    try{
      const response = await axios.get(HttpDefault + "comic/type/showall")
      setTypes(response.data)
    }catch(ex){
     return
    }
   
   
  }
  useEffect(()=>{
    fetchApiType();
  },[types])
  return (
    <>
      <div
 className={`page-wrapper ${open ? "show-sidebar" : ""}`}
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
             <LeftMenu showSidebar={showSidebar} />
        <div class="body-wrapper">
          <header class="app-header">
            <nav class="navbar navbar-expand-lg navbar-light">
              <ul class="navbar-nav">
                <li class="nav-item d-block d-xl-none">
                  <a
                    class="nav-link sidebartoggler nav-icon-hover"
                    id="headerCollapse"
                    href="javascript:void(0)"
                  >
                    <i class="ti ti-menu-2"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                    <i class="ti ti-bell-ringing"></i>
                    <div class="notification bg-primary rounded-circle"></div>
                  </a>
                </li>
              </ul>
            </nav>
          </header>
          <div class="container-fluid">
            <div class="row">
              <Link
                to={"#"}
                className="col-2 btn btn-primary py-8 fs-4 mb-4 rounded-2"
                onClick={addTypeComic}
              >
                ADD TYPE
                <FaPlus className="icon-plus" />
              </Link>
              
              
              
            </div>
            <div className="col-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Name Type
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="input__nametype"
                         
                      />
                    </div>
                  </div>
                  <div className="col-6">
                  {types.map((list,index) => (
                    <div className="row list-chap">
                      <div className="col-6">{list.name}</div>
                      <div className="div__icondel">
                        <FaTrash className="icon-delete-chap" onClick={()=>deleteTypeComic(list._id)}/>
                      </div>
                    </div>
                  ))}
                  </div>
          </div>
        </div>
      </div>
      <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
      <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
      <script src="../assets/js/sidebarmenu.js"></script>
      <script src="../assets/js/app.min.js"></script>
      <script src="../assets/libs/apexcharts/dist/apexcharts.min.js"></script>
      <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
      <script src="../assets/js/dashboard.js"></script>
    </>
  );
};
export default memo(AddTypePage);
