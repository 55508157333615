import { memo } from "react";
import Header from "../header";
import Footer from "../footer";
import { useLocation } from "react-router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const MasterLayout = ({ children, ...props }) => {
  const location = useLocation();
  const check_admin = location.pathname.includes("admin");
  return (

    <div {...props}>
      <ToastContainer/>
      {!check_admin ? <Header /> : <span></span>}
      {children}
      {!check_admin ? <Footer /> : <span></span>}
    </div>
  );
};

export default memo(MasterLayout);
