import React, { memo, useState, useEffect } from "react";
import "./style.scss";
import GoToTop from "../theme/btnGoToTop";
import Breadcrumb from "../theme/breadcrumb/breadcrumb";
import "react-toastify/dist/ReactToastify.css";

import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import LeftMenuAccount from "components/user/LeftMenuAccount";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { HttpDefault } from "utils/http";
import { info } from "sass";
import Explore from "utils/explore";
const ProfilePage = () => {
  const [infor, setInfor] = useState({  
    
  });
  const navigate = useNavigate();
  useEffect(() => {
    const isLogin = sessionStorage.getItem("isLogin");
    if (!isLogin) {
      navigate("/")
    }
    //get data account
    const getDataAccount = async () => {
      const response = await axios.get(`${HttpDefault}v1/user/getDataUser`, {
        params: {
          id: sessionStorage.getItem("id")
        }
      });
      // console.log(response.data);
      setInfor(response.data);
    }
    getDataAccount();
  }, [])
  return (
    <div className="container">
      <Breadcrumb></Breadcrumb>
      <div className="row">
        <LeftMenuAccount account_name={infor.userName ?? "empty"}/>
        <div id="right-profile" className="col-9 right-profile">
          <div className="row information">
            <h3>general information</h3> 
            <div className="col-6 info-detail">
              <div className="group">
                <div className="label">Full name</div>
                <div className="detail"> { infor.userName ?? "empty"} </div>
              </div>
              <div className="group">
                <div className="label">Email</div>
                <div className="detail">{ infor.email ?? "empty"}</div>
              </div>
              <div className="group">
                <div className="label">Birth day</div>
                <div className="detail">{ Explore.UtcToVn(infor.birthday)   }</div>
              </div>
              <div className="group">
                <div>
                <Link to={"/profile/edit"}><FaUserEdit className="icon-edit-account" /></Link>
                </div>
              </div>
            </div>
            <h3>Connet Google Account</h3>
            <div className="col-6 info-detail link">
              <div className="group">
                <div className="label">Status</div>
                <div className="detail">Linked</div>
              </div>
              <div className="group">
                <div className="label">
                  <Link>Change</Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoToTop></GoToTop>
    </div>
  );
};

export default memo(ProfilePage);
