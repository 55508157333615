import { memo, useRef, useState, React } from "react";
import "../../../pages/admin/assets/images/logos/favicon.png";
import "../../../pages/admin/assets/css/styles.min.css";
import { FaBook } from "react-icons/fa";
const LeftMenuAdmin = (props) => {
  const handleSidebarToggle = () => {
    props.showSidebar();
  };
  return (
    <div>
      <aside class="left-sidebar">
        <div>
          <div class="brand-logo d-flex align-items-center justify-content-between">
            <a href="./index.html" class="text-nowrap logo-img">
              <img
                src="../assets/images/logos/dark-logo.svg"
                width="180"
                alt=""
              />
            </a>
            <div
              class="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
              id="sidebarCollapse"
              onClick={handleSidebarToggle}
            >
              <i class="ti ti-x fs-8"></i>
            </div>
          </div>
          {/* <!-- Sidebar navigation--> */}
          <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
            <ul id="sidebarnav">
              <li class="nav-small-cap">
                <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span class="hide-menu">Home</span>
              </li>

              <li class="sidebar-item">
                <a
                  class="sidebar-link"
                  href="/admin/home"
                  aria-expanded="false"
                >
                  <span>
                    <i class="ti ti-layout-dashboard"></i>
                  </span>
                  <span class="hide-menu">Dashboard</span>
                </a>
              </li>
              <li class="nav-small-cap">
                <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span class="hide-menu"> FUNCTIONS</span>
              </li>
              <li class="sidebar-item">
                <a
                  class="sidebar-link"
                  href="/admin/comic-manager"
                  aria-expanded="false"
                >
                  <span>
                    <i class="ti ti-book-2"></i>
                  </span>
                  <span class="hide-menu">Comic Manager</span>
                </a>
              </li>
              <li class="sidebar-item">
                <a
                  class="sidebar-link"
                  href="/admin/add-chapter"
                  aria-expanded="false"
                >
                  <span>
                    <i class="ti ti-file-invoice"></i>
                  </span>
                  <span class="hide-menu">Add chapter</span>
                </a>
              </li>

              <li class="sidebar-item">
                <a class="sidebar-link" href="#" aria-expanded="false">
                  <span>
                    <i class="ti ti-message"></i>
                  </span>
                  <span class="hide-menu">Comment Manager</span>
                </a>
              </li>
              <li class="sidebar-item">
                <a
                  class="sidebar-link"
                  href="/admin/type-comic"
                  aria-expanded="false"
                >
                  <span>
                    <i class="ti ti-message"></i>
                  </span>
                  <span class="hide-menu">Type Manager</span>
                </a>
              </li>
              <li class="sidebar-item">
                <a class="sidebar-link" href="#" aria-expanded="false">
                  <span>
                    <i class="ti ti-user"></i>
                  </span>
                  <span class="hide-menu">Account Manager</span>
                </a>
              </li>
              <li class="nav-small-cap">
                <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span class="hide-menu">AUTH</span>
              </li>
              <li class="sidebar-item">
                <a class="sidebar-link" href="#" aria-expanded="false">
                  <span>
                    <i class="ti ti-login"></i>
                  </span>
                  <span class="hide-menu">Login</span>
                </a>
              </li>
              <li class="sidebar-item">
                <a class="sidebar-link" href="/" aria-expanded="false">
                  <span>
                    <i class="ti ti-logout"></i>
                  </span>
                  <span class="hide-menu">Logout</span>
                </a>
              </li>
            </ul>
          </nav>
          {/* End Sidebar navigation */}
        </div>
      </aside>
      
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li
              class="nav-item d-block d-xl-none"
              onClick={handleSidebarToggle}
            >
              <a
                class="nav-link sidebartoggler nav-icon-hover"
                id="headerCollapse"
              >
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                <i class="ti ti-bell-ringing"></i>
                <div class="notification bg-primary rounded-circle"></div>
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};
export default memo(LeftMenuAdmin);
