import { memo, useRef, useState, React, useEffect } from "react";
import "./style.scss";
import Breadcrumb from "../theme/breadcrumb/breadcrumb";
import { Link, Route, useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaListUl } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import LoadingButton from '@mui/lab/LoadingButton';
import Button from "@mui/material/Button";
import { IoIosArrowBack, IoMdWifi, IoIosEye } from "react-icons/io";
import { IoTimeOutline, IoPerson, IoInformationCircle } from "react-icons/io5";
import { FaRegEye, FaComment, FaHeart, FaTags } from "react-icons/fa";
import TopRank from "../theme/TopRank";
import GoToTop from "../theme/btnGoToTop";
import { colors } from "@mui/material";
import { NumericFormat, numericFormatter } from "react-number-format";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { HttpDefault } from "utils/http";
import CommentNav from '../../../components/user/comment'
import Explore from "utils/explore";
import { MdGeneratingTokens } from "react-icons/md";
import { FaHeartCircleCheck } from "react-icons/fa6";
import NativeAdBanner from "utils/nativeAds";
import AdBanner from "utils/ads";
const ComicPage = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameArray = location.pathname.split("/");
  const slugComic = pathnameArray[pathnameArray.length - 1];
  const [valueComic, setValueComic] = useState();
  const [follow, setFollow] = useState();
  const [loadingFollow, setLoadingFollow] = useState();
  const handleButtonFollow = async () => {
    // check login
    try {
      setLoadingFollow(true);
      const result_session = sessionStorage.getItem("isLogin");
      if (result_session) {
        const result = await axios.post(HttpDefault + `v1/user/${follow ? "unfollow" : "follow"}`, {
          idComic: valueComic._id,
          idUser: sessionStorage.getItem("id")
        });
        if (Object.keys(result.data).includes('success')) {
          setFollow(!follow)
        }
        setLoadingFollow(false);
        return
      }
      navigate("/sign-in")
    } catch (ex) {
      setLoadingFollow(false);
    }

    setLoadingFollow(false);
  }
  // console.log(valueComic.follow);

  useEffect(() => {

    const getDataComicByID = async () => {
      // console.log({ "msg": slugComic });
      const result = await axios.get(HttpDefault + "comic/getComicBySlug", {
        params: { slug: slugComic },
      });
      setValueComic(result.data);
      //check follow
      const follower = result.data.favorites;
      const result_session = sessionStorage.getItem("isLogin");
      if (result_session) {
        follower.map(e => {
          if (e._id === sessionStorage.getItem("id")) {
            setFollow(true)
          }
        })
      }

      // console.log({ "msg": result.data });
    };
    getDataComicByID();
  }, [slugComic]);

  return (
    <>
      {valueComic && valueComic.length !== 0 && (
        <div className="container page-comic">
          <div className="row">
            <div className="col-12 left-comic-body">
              <Breadcrumb />
              <div className="head-comic">
                <h1 className="name"> {valueComic.name}</h1>
                <p className="time">{"[ " + Explore.UtcToVn(valueComic.time_end) + " ]"}</p>
                <div className="detail-content">
                  <div className="col-3 image">
                    <img src={valueComic.image} />
                  </div>
                  <div className="right-content">
                    <ul className="ul-content row">
                    <li className="col-11 col-md-11">
                        <p className="col-6">
                          <IoInformationCircle className="icon" />
                          Another
                        </p>
                        <p className="col-8">{valueComic.another && valueComic.another !== "null" ? valueComic.another : "inprocess"}</p>
                      </li>
                      <li className="col-11 col-md-11">
                        <p className="col-6">
                          <IoPerson className="icon" />
                          Author
                        </p>
                        <p className="col-8">{valueComic.author ? valueComic.author : "inprocess"}</p>
                      </li>
                      <li className="col-11 col-md-11">
                        <p className="col-6">
                          <IoMdWifi className="icon" />
                          Status
                        </p>
                        <p className="col-6">{valueComic.status ? valueComic.status : "inprocess"}</p>
                      </li>
                      <li className="col-11 col-md-11">
                        <p className="col-6">
                          <MdGeneratingTokens className="icon" />
                          Type
                        </p>
                        <p className="col-8" style={{ display: "flex", height: "100%" }}>
                          {valueComic.type_comic
                            .map((value) => value.name)
                            .join(", ")}
                        </p>
                      </li>
                      <li className="col-11 col-md-11">
                        <p className="col-6">
                          <IoIosEye className="icon" />
                          View
                        </p>
                        <p className="col-6">{valueComic.amount_view}</p>
                      </li>
                      <li style={{ width: "100%" }}>
                        {" "}
                        <LoadingButton
                          loading={loadingFollow}
                          variant="contained"
                          className="button-follow"
                          color="warning"
                          onClick={handleButtonFollow}
                        >
                          <FaHeartCircleCheck className="icon-heart" />
                          {follow ? " Unfollow" : " Follow"}
                        </LoadingButton>
                        <div className="follow">
                          <p className="text">
                            <span className="number ">{ valueComic.favorites ? valueComic.favorites.length : "0"}</span>{" "}
                            Follower
                          </p>
                        </div>
                      </li>
                      <li>
                        <a href={`/comic-page/${slugComic}/${valueComic.list_chapter.length > 0 ? valueComic.list_chapter[0].slug : ""}`}>
                          <Button
                            variant="outlined"
                            className="button-read-first">
                            Read First
                          </Button>
                        </a>
                        <a href={`/comic-page/${slugComic}/${valueComic.list_chapter.length > 0 ? valueComic.list_chapter[valueComic.list_chapter.length - 1].slug : ""}`}>
                          <Button variant="outlined" className="button-read-new">
                            Read New
                          </Button>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="div__content-chap-top">
                  <div className="col-lg-8 col-md-12 div__content-chap">
                    <div className="content">
                      <div className="content-head">
                        <p>
                          <IoDocumentTextOutline cla ssName="icon-doc" />
                          CONTENT
                        </p>
                      </div>
                      <div className="content-body">
                        <div dangerouslySetInnerHTML={{ __html: valueComic.description }}></div>
                      </div>
                    </div>
                    <div className="chapList">
                      <div className="chapList-head">
                        <p>
                          <FaListUl className="icon-list" />
                          CHAPTER
                        </p>
                      </div>
                      <ul style={{ marginBottom: "2px" }}>
                        <li className="chapList-chapter">NAME</li>
                        <li className="chapList-update">UPDATE</li>
                        <li className="chapList-view">View</li>
                      </ul>

                      <div className="chapList-content">
                        {valueComic.list_chapter.map((value, key) => (
                          <ul>

                            <li className="chapList-content-chapter">
                              <a href={`/comic-page/${slugComic}/${value.slug}`}>
                                {value.name}
                              </a>
                            </li>
                            <li className="chapList-content-update">
                              {Explore.convertDataToK(value.time_upload)}
                            </li>
                            <li className="chapList-content-view">
                      {
                        <NumericFormat
                          value={valueComic.view_chapter && valueComic.view_chapter[value._id] ? valueComic.view_chapter[value._id]: "0"}
                          displayType="text"
                          thousandSeparator={true}
                        ></NumericFormat>
                      }
                    </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                    <div>
            <CommentNav valueComic={valueComic} setValueComic={setValueComic}></CommentNav>
            {/* <NativeAdBanner url={"//pl23880431.highrevenuenetwork.com/11079db5c9143ad07e82a17e0217d6ef/invoke.js"} id={"container-11079db5c9143ad07e82a17e0217d6ef"}/> */}
            {/* <AdBanner option={`atOptions = {
		'key' : 'c09dac916066632c3fd0f2983018b5d7',
		'format' : 'iframe',
		'height' : 90,
		'width' : 728,
		'params' : {}
	};`} url={"//www.topcreativeformat.com/c09dac916066632c3fd0f2983018b5d7/invoke.js"} id={''}/> */}
          </div>
                  </div>
                  <div className="col-lg-3 col-md-12 div__toprank">
                    <TopRank></TopRank>
                  </div>

                </div>
              </div>
            </div>
            <div className="right-comic-body">
            </div>
          </div>
          <GoToTop></GoToTop>
            


        </div>

      )}

    </>
  );
};

export default memo(ComicPage);
