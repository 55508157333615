import { memo, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import "./style.scss"
function Paginations(total) {
  // Pagination
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = total; // Số trang trong tổng số trang
  

  const handlePageChange = (event, page) => {
    // Xử lý thay đổi trang ở đây (fetch dữ liệu mới, cập nhật state, vv.)

    // if(page ==1) return
    const currentParams = new URLSearchParams(location.search);
    const check_page = currentParams.get("page");
    if (check_page) {
      currentParams.set('page', page);
    } else {
      currentParams.append('page', page);
    }

    setCurrentPage(page);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.location.href = newUrl;
    // navigate(`?page=${page}`);
    // navigate(`?page=${page}`);
    //console.log(page);
  };
  // End Pagination
  useEffect(() => {
    const updatePage = () => {
      // console.log({msg:"naga"});
      const currentParams = new URLSearchParams(location.search);
      const check_page = currentParams.get("page");
       
      if (check_page) {
        // console.log({msg:check_page});
        setCurrentPage(Number(check_page));
        
      } else {
        setCurrentPage(1);
      }
      
    }
    updatePage();
  }, [currentPage,total]);
  return (
    <div>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          className="pagtination"
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="primary"
          size="large"
        />
      </Box>
    </div>
  );
};
export default Paginations;
