import React, { memo, useState, useEffect } from "react";
import "./style.scss";
import GoToTop from "../../theme/btnGoToTop";
import Breadcrumb from "../../theme/breadcrumb/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import LeftMenuAccount from "components/user/LeftMenuAccount";
import axios, { AxiosError } from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import { HttpDefault } from "utils/http";
import { useNavigate   } from 'react-router-dom';
const ChangePassword = () => {

  const navigate = useNavigate();
   useEffect(()=>{
     const isLogin=sessionStorage.getItem("isLogin");
     if(!isLogin){
       navigate("/")
     }
   },[])

  const [cookies, setCookie] = useCookies(["accessToken"]);
  const [formData, setFormdata] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",

  });

  const handleChange = (e) => {
    setFormdata({ ...formData, [e.target.name]: e.target.value });
  };
 // console.log(cookies.accessToken);
  const handleChangePassword = async () => {
   if(formData.oldPassword ==""|| formData.newPassword ==""|| formData.confirmPassword ==""){
    return
   }
    if(formData.newPassword != formData.confirmPassword){
      return toast.error("Passwords do not match")
    }
    try {
      const response = await axios.post(
        HttpDefault + "v1/user/changePassword",
        {
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
          confirmPassword: formData.confirmPassword,
          id: sessionStorage.getItem("id"),
        },
        {
          headers: {
            accessToken: cookies.accessToken,
          },
        }
      );
      
      // console.log(response);
      if (response.status === 200) {
        return toast.success("Update success");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Password wrong");
      } else {
        // console.log(error);
        toast.error("Error");
      }
     
    }
  };

  //console.log(formData)
  return (
    <div className="container">
      <Breadcrumb></Breadcrumb>
      <div className="row">
        <LeftMenuAccount />
        <div className="col-6 col-md-8 col-sm-10">
          <div className="row right-profile">
            <h3>information</h3>
            <div className="form-edit">
              <form>
                <lable>Old Password</lable>
                <input
                  name="oldPassword"
                  type="password"
                  value={formData.oldPassword}
                  
                  // value={formData.email}
                   onChange={handleChange}
                ></input>
                <lable>New Password</lable>
                <input
                  name="newPassword"
                  type="password"
                  value={formData.newPassword}
                  
                  // value={formData.email}
                   onChange={handleChange}
                ></input>
                <lable>Confirm Password</lable>
                <input
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  // value={formData.email}
                   onChange={handleChange}
                ></input>
                <button onClick={handleChangePassword} type="button">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>

      
      </div>

      <GoToTop></GoToTop>
    </div>
  );
};

export default memo(ChangePassword);
