import "./style.scss"
import React, { memo, useState, useEffect  } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IoMdTime } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import Explore from "utils/explore";
import axios from "axios";
import { HttpDefault } from "utils/http";
import { useLocation } from "react-router-dom";
import NativeAdBanner from "utils/nativeAds";
const ButtonReply = ({ onClick }) => {
    return (
        <>
            <div className="buttton_reply" onClick={onClick}>
                Reply
            </div>
        </>
    )
}
const ComponentReply = ({ handleSendComment, valueComment, loading }) => {
    const [indexOpen, setIndexOpen] = useState();
    const handleOnClick = (index) => {
        setIndexOpen(index);
    }
    return (
        <>
            {loading ? (
                <div className="grid-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress color="warning" />
                </div>
            ) :
                valueComment.map((e, key) => (
                    <div key={key} className="component__comment">
                        <div className="div__ifuser">
                            <div className="img__user">
                                <img src="https://www.waifu.com.mx/wp-content/uploads/2023/05/Waifu-Wishlist-Must-Have-Traits-and-Qualities-in-a-Beloved-Character.jpg"></img>
                                <ButtonReply onClick={() => handleOnClick(key)} />
                            </div>
                        </div>
                        <div className="div__body-reply">
                            <div className="div__body">
                                <div className="sub-content">
                                    <div className="name"> {
                                        Object.keys(e).includes("user") ?
                                            e.user.user.username === "null" ? "Nameless" : e.user.user.username : e.name
                                    } </div>
                                    {
                                        e.type === 0 ? (<div className="level">Guest</div>) : (
                                            <div className="level-member">Member</div>
                                        )
                                    }
                                    <div className="time-comment"><IoMdTime /> {Explore.convertDataToK(e.time)}</div>
                                    <div className="chapter">{Object.keys(e).includes("chapter") ?
                                                     e.chapter.name: ""}</div>
                                </div>
                                <div className="reply__input-hidden">
                                    <div className="content-comment">
                                        {/* {e.content} */}
                                        <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                                    </div>
                                </div>


                            </div>
                            {key === indexOpen && <ComponentInput handleSendComment={handleSendComment} index={key} setIndexOpen={setIndexOpen} idComment={e._id}></ComponentInput>}
                            {/* //reply comment */}
                            {e.reply.length > 0  ?  e.reply.map((d, k) => (<div key={k} className="component__comment">
                                <div className="div__ifuser">
                                    <div className="img__user">
                                        <img src="https://www.waifu.com.mx/wp-content/uploads/2023/05/Waifu-Wishlist-Must-Have-Traits-and-Qualities-in-a-Beloved-Character.jpg"></img>

                                    </div>
                                </div>
                                <div className="div__body-reply">
                                    <div className="div__body">
                                        <div className="sub-content">
                                            <div className="name">{
                                                Object.keys(d).includes("user") ?
                                                    d.user.user.username === "null" ? "nameless" : d.user.user.username : d.name
                                            }</div>
                                            {
                                                d.type === 0 ? (<div className="level">Guest</div>) : (
                                                    <div className="level-member">Member</div>
                                                )
                                            }


                                            <div className="time-comment"><IoMdTime /> {Explore.convertDataToK(d.time)}</div>
                                            <div className="chapter">{Object.keys(d).includes("chapter") ?
                                                     d.chapter.name: ""}</div>
                                        </div>
                                        <div className="reply__input-hidden">
                                            <div className="content-comment">
                                                {/* {d.content} */}
                                                <div dangerouslySetInnerHTML={{ __html: d.content }}></div>
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>)

                            ) : ""}
                        </div>

                    </div>
                ))
            }

        </>
    )
}
const ComponentInput = ({ handleSendComment, index, setIndexOpen, valueComic, setValueComment, valueComment, type_comment, idComment }) => {
    const checkLogin = sessionStorage.getItem("id");
    const [value, setValue] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <>
            <div className="input__comment">
                {/* <textarea placeholder="Invite you to discuss"></textarea> */}
                <ReactQuill className="input__quill" placeholder="Invite you to discuss" theme="snow" value={value} onChange={setValue} />
                <div className="content__name-email-button">
                    {!checkLogin && (<input type="text" className="input__name " placeholder="Name" value={name} maxlength="50"
                        onChange={handleNameChange} />)}
                    {!checkLogin && (<input type="text" className="input__email" placeholder="Email (not required)" value={email} maxlength="50"
                        onChange={handleEmailChange} />)}

                    <div className="button__submit" onClick={() => handleSendComment(name, email, value, setValue, index, setIndexOpen, checkLogin, idComment)}>SEND</div>
                </div>

            </div>
        </>
    )
}

const CommentNav = ({ valueComic, idChapter }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    const [valueComment, setValueComment] = useState([
        // {
        //     id: "1",
        //     chapter: {
        //         name: "martial peak"
        //     },
        //     user: {
        //         name: "naruto"
        //     },
        //     content: "what the hack",
        //     time: "2023-12-20T16:03:35.824+00:00",
        //     reply: [{
        //         chapter: {
        //             name: "martial peak"
        //         },
        //         user: {
        //             name: "naruto"
        //         },
        //         content: "what the hack",
        //         time: "2023-12-20T16:03:35.824+00:00"
        //     }]
        // }
    ]);


    useEffect(() => {
        setLoading(true)
        let sort_date = valueComic.comment;
        sort_date = sort_date.sort((a, b) => {
            let aa = new Date(a.time);
            let bb = new Date(b.time);
            return bb - aa;
        });
        setValueComment(sort_date);
      
        setLoading(false)

    }, [valueComic])
    const handleSendComment = async (name, email, value, setValue, index, setIndexOpen, checkLogin, idComment) => {
        try {
            if(!value || value===''){
                alert("You should input value content chat!!")
                return
            }
            if(value.length > 500){
                alert("Content only 1000 number text!!")
                return
            }
            if (!name) {
                if (checkLogin) {

                } else {
                    alert("You should input name!!")
                    return
                }

            }
 
            if (index === 0 || index) {
                ///
                let data = {}
                if (checkLogin) {
                    data = {
                        idComic: valueComic._id,
                        idUser: checkLogin,
                        content: value,
                        user: {
                            user: {
                                username: sessionStorage.getItem('name')
                            }
                        },
                        type: 1,

                    }
                    if (idChapter) {
                        data.idChapter = idChapter
                    }
                } else {
                    data = {
                        idComic: valueComic._id,
                        content: value,
                        name: name,
                        type: 0,

                    }
                    if (idChapter) {
                        data.idChapter = idChapter
                    }
                }
                data.idComment = valueComment[index]._id;
                data.time = new Date();
                const response = await axios.post(`${HttpDefault}comic/replycomment`, data);
               
                if (Object.keys(response.data).includes("success")) {
                    //sucssess
                    if (Object.keys(data).includes("idChapter")) {
                        let temp_paths = location.pathname.split('/');
                        temp_paths = temp_paths[temp_paths.length - 1]
                        data.chapter = {
                            name: temp_paths.replace("-"," ")
                        } 
                    }  
                    data.idComment = response.data.success;
                    let dataConfig = [...valueComment]
                    dataConfig[index].reply = [data, ...dataConfig[index].reply]
                    setValueComment(dataConfig)
                  
                } else {
                    //fail
                }
                setLoading(false)
                setIndexOpen('')
                setValue('')
                return;
            }
            setLoading(true)
            // const data = {
            //     chapter: {
            //         name: "martial peak"
            //     },
            //     user: {
            //         name: name
            //     },
            //     content: value,
            //     time: new Date(),
            //     reply: []
            // }
            // setValueComment([data, ...valueComment])
            let data = {}
            if (checkLogin) {
                data = {
                    idComic: valueComic._id,
                    idUser: checkLogin,
                    content: value,
                    type: 1,
                    reply: []
                }
                if (idChapter) {
                    data.idChapter = idChapter
                }
            } else {
                data = {
                    idComic: valueComic._id,
                    // idUser: checkLogin,
                    content: value,
                    name: name,
                    type: 0,
                    reply: []
                }
                if (idChapter) {
                    data.idChapter = idChapter
                }
            }
            const response = await axios.post(`${HttpDefault}comic/addcomment`, data);
            if (Object.keys(response.data).includes("success")) {
                //sucssess
                data.idComment = response.data.success;
                data._id = response.data.success;
                if (Object.keys(data).includes("idChapter")) {
                    let temp_paths = location.pathname.split('/');
                    temp_paths = temp_paths[temp_paths.length - 1]

                    data.chapter = {
                        name: temp_paths.replace("-"," ")
                    } 
                }  
                if(Object.keys(response.data).includes("idUser")){
                    data.user = {

                        user: {
                            username: sessionStorage.getItem('name')
                        }
    
                    }
                }
               
                data.time = new Date();
                const result_data = [data, ...valueComment]
                setValueComment(result_data)
               
            } else {
                //fail
            }
            setLoading(false)
            setValue('')
            return;
        } catch (error) {
            
            setLoading(false)
            setValue('')
            return;
        }

    }
    return (
        <>
            <div className="block__comment">
                <div className="block_total">
                    <p>Toltal Comment ({valueComment.length})</p>
                </div>
                <ComponentInput setValueComment={setValueComment} handleSendComment={handleSendComment} valueComic={valueComic} valueComment={valueComment}  ></ComponentInput>

                <div className="block__anwser">
                    <ComponentReply handleSendComment={handleSendComment} valueComment={valueComment} loading={loading} />
                </div>
                
            </div>
        </>
    )
}
export default memo(CommentNav);