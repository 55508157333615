import { memo, useRef, useState, React, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./style.scss";
import { GrFormNext } from "react-icons/gr";
import OwlCarousel from "react-owl-carousel";
import { Link, Route } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoTimeOutline } from "react-icons/io5";
import { FaRegEye, FaComment, FaHeart } from "react-icons/fa";
import Safe from "react-safe"
import Explore from "../../../utils/explore";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TopRank from "../theme/TopRank";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Paginations from "../theme/pagination/index";
import styled from "@emotion/styled";
import GoToTop from "../theme/btnGoToTop";
import axios from "axios";
import AdBanner from "utils/ads";
import NativeAdBanner from "utils/nativeAds";
import { HttpDefault } from "utils/http";
import imageLogo from "../../../public/new.png"
import imageLogo1 from "../../../public/new1.png"
import PopupUI from "../../../components/user/popup"
// import GoToTop from "../theme/btnGoToTop";

const HomePage = () => {
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page');
  // console.log(page);
  const [valueComic, setValueComic] = useState();
  const owlCarouselRef = useRef();
  const [topweek, setTopWeek] = useState([]);
  const [nominated, set_nominated] = useState([]);
  const [most_today, set_most_todays] = useState([]);
  const [value, setValue] = useState("1");
  const [valuePa, setValuePa] = useState(0);
  const [loadingMostViewToday, setLoadingMostViewToday] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNext = () => {
    owlCarouselRef.current.next(1000);
  };

  const handleBack = () => {
    owlCarouselRef.current.prev(1000);
  };
  const fetchPagination = async () => {
    const result = await axios.get(`${HttpDefault}comic/amountcomic`);
    // console.log(result.data);
    setValuePa(result.data)
  }
  const showAllComic = async () => {
    setLoadingMostViewToday(true);
    if (page === null) {
      const result = await axios.get(`${HttpDefault}comic/showComicPage?page=${1}`);
      set_most_todays(result.data);
    } else {
      const result = await axios.get(`${HttpDefault}comic/showComicPage?page=${page}`);
      set_most_todays(result.data);
    }
    setLoadingMostViewToday(false)
  }
  const showNominated = async () => {
    const result = await axios.get(`${HttpDefault}comic/shownominated`);
    // console.log(result.data);
    set_nominated(result.data);
  }
  const showTopweek = async () => {
    const result = await axios.get(`${HttpDefault}comic/shownominated`);
    // console.log(result.data);
    setTopWeek(result.data);
  }

  useEffect(() => {
    fetchPagination();
    showAllComic();
    showNominated();
    showTopweek();

  }, [page]);
  return (
    <>
      {/* <div className="homepage"> */}
      <div>
        <PopupUI />
      </div>

      <div className="container">
        <div className="col-xl-12 homepage__check">
          <div className="row">
            <a href="" className="homepage-link-next-nominated">
              <p>
                Nominated stories <GrFormNext className="homepage_icon-next" />
              </p>
            </a>
            <OwlCarousel
              className="owl-theme"
              items={5}
              startPosition={0}
              // 1
              animateOut="fadeOut" // Hiệu ứng khi chuyển ra khỏi slide hiện tại
              animateIn="fadeIn"
              dots={false}
              // nav={true}
              // loop
              loop={true}
              autoplay={true}
              smartSpeed={1000}
              autoplayTimeout={6000} // thời gian giữa các slide (đơn vị là mili giây)
              autoplayHoverPause
              margin={8}
              stagePadding={4}
              ref={owlCarouselRef}
              responsive={{
                0: {
                  items: 2, // Hiển thị 1 item khi màn hình có chiều rộng dưới 600 pixel
                },
                600: {
                  items: 3, // Hiển thị 3 items khi màn hình có chiều rộng từ 600 pixel đến 900 pixel
                },
                1200: {
                  items: 6, // Hiển thị 2 items khi màn hình có chiều rộng từ 1200 pixel trở lên
                },
              }}
            >
              {
                nominated.map((item, index) =>
                (
                  <a key={index} href={`/comic-page/${item.slug}`}>
                    <div className="item">
                      <img src={item.image} />
                      <div className="content_nominated">
                        <p>{item.name.length > 22 ? item.name.substring(0, 19) + "..."
                          : item.name}</p>
                        <div className="row">
                          <div className="col-6">
                            <span>{item.list_chapter && item.list_chapter.length > 0 ? item.list_chapter[0].name
                              : "new"}</span>
                          </div>
                          <div className="col-6">
                            <span>
                              <IoTimeOutline className="icon-time" />
                              {Explore.convertDataToK(item.list_chapter && item.list_chapter.length > 0 ? item.list_chapter[0].time_upload : "0h")}
                              {/* 29 hours ago */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )
                )
              }
              {
                /* <Link>
                  <div className="item">
                    <img src="https://manhuaaz.com/wp-content/uploads/2023/03/douluo-dalu-ii-jueshi-tangmen-193x278.jpg" />
                    <div className="content_nominated">
                      <p>Why Heart breaking</p>
                      <div className="row">
                        <div className="col-6">
                          <span>Chapter 10</span>
                        </div>
                        <div className="col-6">
                          <span>
                            <IoTimeOutline className="icon-time" />
                            29 hours ago
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link> */
              }
            </OwlCarousel>

            <div className="homepage_button-next" onClick={handleBack}>
              <IoIosArrowBack className="icon-next" />
            </div>


            <div className="homepage_button-back" onClick={handleNext}>
              <GrFormNext className="icon-back" />
            </div>

          </div>

          <div className="row">
            <div className="col-lg-8 col-md-12 col-xl-8  content-main-left">
              <Link className="homepage-link-next-nominated">
                <p>
                  Most Viewed Today
                  <GrFormNext className="homepage_icon-next" />
                </p>
              </Link>
              <br></br>
              {
                loadingMostViewToday ? (
                  <div className="grid-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress color="warning" />
                  </div>
                ) : (<div className="grid-container">
                  {most_today.map((item, key) => {
                    if (key === 24) return null;
                    return (
                      <div key={key} className="grid-item">
                        <a href={`comic-page/${item.slug}`}>
                          <div className="image">
                            <img className="icon-hot" src={imageLogo}></img>
                            <img src={item.image} alt="" loading="lazy"></img>
                            {/* <div className="content-image">
                              <FaRegEye className="content-image-icon" />
                              {Explore.roundMath(item.count_seen)}
                              <FaComment className="content-image-icon" />
                              {Explore.roundMath(item.count_comment)}
                              <FaHeart className="content-image-icon" />
                              {Explore.roundMath(item.count_like)}
                            </div> */}
                          </div>
                        </a>
                        <div className="content-detail">
                          <p className="title">
                            {item.name.length > 22 ? item.name.substring(0, 22) + "..." : item.name}
                          </p>
                          {item.chapter.map((i, key) => (
                            <a key={key} href={`/comic-page/${item.slug}/${i.slug}`}>
                              <div className="chapter">
                                <p className="name-title"> {i.name} {(new Date() - new Date(i.time_upload)) / (1000 * 60 * 60 * 24) < 3 ? <img className="logo-icon-1"  src={imageLogo1}></img> : ""} </p>
                                <p className="name-time">
                                  {" "}
                                  {Explore.convertDataToK(i.time_upload)} 
                                </p>
                              </div>
                            </a>
                          ))}
                          {/* <p className="">{item.name}</p> */}
                        </div>
                      </div>
                    );
                  })}
                </div>)
              }
 
              {Paginations(valuePa, page)}
              {/* <NativeAdBanner url={"//pl23880431.highrevenuenetwork.com/11079db5c9143ad07e82a17e0217d6ef/invoke.js"} id={"container-11079db5c9143ad07e82a17e0217d6ef"}/> */}
            </div>

            <div className="col-lg-3 col-md-10-toprank content-main-right">
              <TopRank
                value={value}
                topweek={topweek}
                handleChange={handleChange}
              ></TopRank>

            </div>

          </div>

        </div>

      </div>

      {/* </div> */}
      {/* <GoToTop></GoToTop> */}
    </>
  );
};
export default memo(HomePage);
