import React, { memo, useState,useRef, useEffect } from "react";
import "./style.scss";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineBallot } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { SlArrowDown } from "react-icons/sl";
import { PiUserRectangleLight } from "react-icons/pi";
const LeftMenuAccount = ({account_name}) => {
  const [stateOpenMenu, setStateOpenMenu] = useState(false);

  const navRef = useRef();
  const openMenu = () => {
    setStateOpenMenu(!stateOpenMenu);
    navRef.current.classList.toggle("active");
  };
  return (
    <>
 
     <div id="left-profile" className="left-profile col-3 col-md-12" ref={navRef} >
        <div className="account">
          <div className="row">
            <div className="col-6">

            <img className="img-account" src={"/static/media/template-user.e73cc3456fae0ad7d29e.jpg"} /> 
            
            </div>
            <div id="account-name" className="col-6 account-name">
              <p className="title-account">Account name</p>
              <p className="name">{account_name ? account_name : ""}</p>
              <SlArrowDown className="iconOpen" onClick={openMenu}/>
            </div>
          </div>
        </div>
        <ul className="left-menu-account">
          <li>
            
            <Link to={"/profile"}><MdOutlineBallot className="icon-menu" />General information</Link>
          </li>
          <li>
            
            <Link to={"/profile/edit"}><FaInfoCircle className="icon-menu" />Account information</Link>
          </li>
          <li>
             <Link to={"/profile/follow"}><FaBook className="icon-menu" />Follow</Link> 
          </li>
         
          <li>
            
            <Link to={"/profile/change-password"}><FaExchangeAlt className="icon-menu" /> Change password</Link>
           
          </li>
          {/* <li>
            <FaSignOutAlt className="icon-menu" />
            Logout
          </li> */}
        </ul>
      </div>

    
    </>
  );
};

export default memo(LeftMenuAccount);
