import { memo } from "react"
import './style.scss'
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <>
            <div className="footer">

                    <div className="row_footer">
                        <div className="col-6 footer-left">
                            <h1 style={{'color':'#ffae1f'}}>NobiManhua</h1>
                            <br></br>
                            <div>
                                <span>Contact Copyright</span>
                                <span>Privacy Policy</span>
                            </div><br></br>
                            <div>
                                <p>Email : trumnhanpham9999@gmail.com</p>
                                <p>This is a website that aggregates images from other websites for the purpose of building a community of comic book enthusiasts. If there are any images or artworks related to copyright, please contact us via email. The content will be removed if it violates copyright.</p>
                                
                            </div>
                        </div>
                        <div className="col-6 footer-right">
                            <div><span>KeyWords</span></div>
                            <div className="list-keyworks">
                                <ul>
                                    <a href="/find-comic?type=Manhua"><li>manhua</li></a>
                                    <a href="/find-comic?type=Anime"><li>anime</li></a>
                                    <a href="/find-comic?type=Fantasy"><li>fantasy</li></a>
                                    <a href="/find-comic?type=Harem"><li>harem</li></a>
                                    <a href="/find-comic?type=Manhwa"><li>anime</li></a>
                                </ul>
                            </div>
                        </div>
      

                </div>
            </div>
        </>
    );

}
export default memo(Footer);