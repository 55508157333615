import React, { memo, useState, useEffect } from "react";
import "./style.scss";
import GoToTop from "../../theme/btnGoToTop";
import Breadcrumb from "../../theme/breadcrumb/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import LeftMenuAccount from "components/user/LeftMenuAccount";
import axios, { AxiosError } from "axios";
import { ToastContainer, toast } from "react-toastify";
import { HttpDefault } from "utils/http";
import { useNavigate   } from 'react-router-dom';
import imageMain from '../../../../public/logo.png';
const FormData = require('form-data');

const EditProfile = () => {

  const navigate = useNavigate();
  useEffect(()=>{
    const isLogin=sessionStorage.getItem("isLogin");
    if(!isLogin){
      navigate("/")
    }
  },[])
  const [formData, setFormdata] = useState({
    email: "",
    userName: "",
    image: "",
    birthday: "",
  });

  useEffect(() => {
    sessionStorage.setItem("inProcess", true);
    const getDataUser = async () => {
      try {
        const response = await axios.get(
          HttpDefault+"v1/user/getDataUser",
          {
            params: {
              id: sessionStorage.getItem("id"),
            },
          }
        );
       // console.log(response);
        if (response) {
          setFormdata({
            ...formData,
            email: response.data.email,
            userName: response.data.userName,
            birthday: response.data.birthday,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        sessionStorage.setItem("inProcess", false);
      }
    };
    getDataUser();
  }, []);
  function formatDate(date) {
    if (!date) {
      return "";
    }
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  }
  const handleChange = (e) => {
    setFormdata({ ...formData, [e.target.name]: e.target.value });
  };
  const [files, setFiles] = useState([]);
  const handleImageChange = (event) => {
    const uploadFiles = event.target.files;
    setFiles(uploadFiles);
  };
  const renderImg = () => {
    return [...files].map((file) => (
      <div>
        <img className="avatar-change" src={URL.createObjectURL(file)} />
      </div>
    ));
  };
  /// update
//  const [selectedImage, setSelectedImage] = useState(null);
// const [email, setEmail] = useState('');
// const [username, setUsername] = useState('');
// const [birthday, setBirthday] = useState('');
const formUpdate = new FormData();
  const handleUpdate = async () => {
    // cv: convert
    const cvBirthday= new Date(formData.birthday)
    if(cvBirthday > Date.now()){
      return toast.error("Select a date in the future, thank you")
    }
    try {
      Object.keys(files).forEach(key=>{
        formUpdate.append("photos", files.item(key))
      })

      formUpdate.append('birthday', formData.birthday);
      formUpdate.append('username', formData.userName);
      formUpdate.append('id', sessionStorage.getItem("id"));
      const response = await axios.post(HttpDefault+"v1/user/updateUser", formUpdate);
      // console.log("update",response);
      const check  = await axios.get(response.data.URL_avatar)
      if(check.status < 400){
        localStorage.setItem("avatar", response.data.URL_avatar)
      }
      
      if(response.status ===200){
       return toast.success("Update success")
      }
      else{
        return toast.error("update fail")
      }
    } catch (error) {
      console.log(error);
      toast.error("something wrong")
    }
  };

  //console.log(formData.birthday)
  return (
    <div className="container">
      <Breadcrumb></Breadcrumb>
      <div className="row">
        <LeftMenuAccount />
        <div className="col-6 col-md-8">
          <div className="row right-profile">
            <h3>information</h3>
            <div className="form-edit">
              <form>
                <lable>User name</lable>
                <input
                  name="userName"
                  type="text"
                  value={formData.userName}
                  // value={formData.email}
                  onChange={handleChange}
                ></input>
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  disabled
                  // onChange={handleChange}
                ></input>

                <lable>Birthday</lable>
                <input
                  name="birthday"
                  type="date"
                  value={formatDate(formData.birthday)}
                  onChange={handleChange}
                ></input>

                <lable className="avatar-form">Avatar</lable>
                <input
                  className="avatar-form"
                  name="image"
                  type="file"
                  onChange={handleImageChange}
                  // value={formData.email}
                ></input>
                <div className="avatar-form render">{renderImg()}</div>
                <button onClick={handleUpdate} type="button">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* <div className="col-3 col-md-2 avatar">

          <lable>Avatar</lable>
          <input
            name="surname"
            type="file"
            onChange={handleImageChange}
            // value={formData.email}
            // onChange={handleChange}
          ></input>
          <div>{renderImg()}</div>
        </div> */}
      </div>

      <GoToTop></GoToTop>
    </div>
  );
};

export default memo(EditProfile);
